<template>
  <div class="person-create with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Adding {{ relationType }} of {{ personFullName }}</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <mcr-loading-indicator class="content" v-if="loading" :loading="true" />
    <person-full-edit-form v-else class="body" ref="form" :form-initial-data="initialData">
      <template v-slot:extra-fields>
        <div v-if="possibleChildrenList.length || possibleParentsList.length" class="section">
          <div v-if="possibleChildrenList.length" class="children-block">
            <div class="label">Include the following individuals as children:</div>
            <multiple-choice-select
              class="text-sm"
              :options="possibleChildrenList"
              :checked="selectedPossibleChildrenIds"
              @change="childrenIdsChange"
            ></multiple-choice-select>
          </div>

          <div v-if="possibleParentsList.length" class="parents-block">
            <div class="label">Parents</div>
            <radio-select
              v-if="possibleParentsList.length"
              :options="possibleParentsList"
              :selected="selectedPossibleParentsIds"
              name="parents-radio"
              class="text-sm"
              @change="parentsIdsChange"
            ></radio-select>
          </div>
        </div>

        <marriage-details-block
          v-if="showMarriageDetails"
          :family-tree-id="initialData.family_tree_id"
          class="section"
          ref="marriageBlock"
        ></marriage-details-block>
      </template>
    </person-full-edit-form>

    <div class="action-buttons right-align">
      <mcr-button class="white bold" :disabled="formLoading" @click="closeModal">Cancel</mcr-button>
      <mcr-button class="save bold" :loading="formLoading" @click="saveClick">Save</mcr-button>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import RadioSelect from '@common/elements/filters/radioSelect';
import isEmpty from 'lodash/isEmpty';
import CloseIcon from 'vue-material-design-icons/Close';
import {mapGetters} from 'vuex';

import MultipleChoiceSelect from '@/base/elements/filters/multipleChoiceSelect';

import PersonFullEditForm from '@/components/common/tree/PersonFullEditForm';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import MarriageDetailsBlock from '@/components/modules/familyTree/tree/modals/fields/MarriageDetailsBlock';

export default {
  props: {
    relationType: String,
    fullName: String,
    initialData: Object,
    showMarriageDetails: {type: Boolean, default: false},
    saveClickHandler: Function,
    possibleChildrenList: Array,
    possibleParentsList: Array,
    childrenIdsChange: Function,
    parentsIdsChange: Function,
  },
  computed: {
    ...mapGetters([
      'familyTreePersonOptionsLoadingState',
      'familyTreePersonOptionsState',
      'familyTreePersonCreateErrorsState',
      'familyTreePersonCreateLoadingState',
      'parenthoodCreateLoadingState',
      'coupleCreateLoadingState',
      'familyTreeDrawLoadingState',
      'familyTreePersonRelativesLoadingState',
    ]),
    loading() {
      return this.familyTreePersonOptionsLoadingState || isEmpty(this.familyTreePersonOptionsState);
    },
    formLoading() {
      return (
        this.familyTreePersonCreateLoadingState ||
        this.parenthoodCreateLoadingState ||
        this.coupleCreateLoadingState ||
        this.familyTreeDrawLoadingState ||
        this.familyTreePersonRelativesLoadingState
      );
    },
    personFullName() {
      return this.fullName || UNKNOWN_NAME;
    },
    selectedPossibleChildrenIds() {
      const selected = this.possibleChildrenList.filter(option => option.selected);
      return selected.length ? selected.map(option => option.id) : [];
    },
    selectedPossibleParentsIds() {
      const selected = this.possibleParentsList.find(option => option.selected);
      return selected ? selected.id : null;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    saveClick() {
      const person = this.$refs.form.getModifiedPersonForSave();
      const marriageData = this.$refs.marriageBlock ? this.$refs.marriageBlock.getDataForSave() : null;
      this.saveClickHandler(person, marriageData);
    },
  },
  components: {RadioSelect, MultipleChoiceSelect, PersonFullEditForm, CloseIcon, mcrButton, MarriageDetailsBlock},
  name: 'personCreateModalContent',
};
</script>

<style lang="scss" scoped>
.person-create {
  .heading {
    padding-right: 50px;
  }

  .children-block,
  .parents-block {
    .label {
      color: $neutral-500;
      margin-bottom: 10px;
    }
  }

  .save {
    min-width: 100px;
  }

  @media only screen and (min-width: $breakpoint-phablet) {
    min-width: 720px;
    width: 720px;
    min-height: 320px;
  }
}
</style>
