<template>
  <div class="familytree-card-container">
    <family-tree-card
      ref="card"
      :person="person"
      :double-click="false"
      @click-card="openQuickCreateSidebar"
      @dblclick-card="openQuickCreateSidebar"
    ></family-tree-card>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import {PARENT_RELATION_TYPE, UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import FamilyTreeCard from '@/components/modules/familyTree/tree/familyTreeCard';
import PersonQuickCreate from '@/components/modules/familyTree/tree/modals/quick/PersonQuickCreate';
import {
  createGhostParentHandler,
  getFirstEnCnSurnames,
  getPossibleChildrenList,
} from '@/components/modules/familyTree/tree/services';
import {
  openQuickSidebar,
  closeQuickSidebar,
  openCreateGhostParentModal,
} from '@/components/modules/familyTree/tree/services.modals';

export default {
  props: {
    person: Object,
    handleClickAllowed: Boolean,
  },
  computed: {
    ...mapGetters(['familyTreeDetailsIdState', 'familyTreeStartPersonIdState', 'familyTreePersonsByIdState']),
    initialPersonData() {
      const child = this.familyTreePersonsByIdState[this.person.child.object_id];
      return {
        gender: this.person.gender,
        hierarchy_level: this.person.hierarchy_level,
        generation_number: this.person.child.generation_number ? this.person.child.generation_number - 1 : null,
        family_tree_id: this.familyTreeDetailsIdState,
        surnames: this.person.gender === 'm' && child && child.surnames ? getFirstEnCnSurnames(child.surnames) : null,
      };
    },
  },
  methods: {
    async openQuickCreateSidebar(person) {
      if (!this.handleClickAllowed) {
        return;
      }
      let possibleChildrenList = person.spouse ? getPossibleChildrenList(PARENT_RELATION_TYPE, person.child, this) : [];
      let childrenIds = possibleChildrenList.filter(option => option.selected).map(option => option.id);
      const props = {
        relationType: this.person.gender === 'm' ? 'Father' : this.person.gender === 'f' ? 'Mother' : 'Parent',
        fullName: this.familyTreePersonsByIdState[person.child.object_id].full_name || UNKNOWN_NAME,
        initialData: this.initialPersonData,
        possibleChildrenList: possibleChildrenList,
        possibleParentsList: [],
        saveClickHandler: async formData => {
          await createGhostParentHandler(formData, person.child, childrenIds, false, this);
          closeQuickSidebar(this);
        },
        fullFormOpener: formData => {
          openCreateGhostParentModal(formData, person.child, possibleChildrenList, this);
        },
        childrenIdsChange: value => {
          childrenIds = value;
        },
      };

      let events = {
        opened: () => {
          this.$emit('open-sidebar', person.id);
        },
      };

      const sidebarProps = {
        id: this.person.id,
        personId: this.person.id,
      };
      openQuickSidebar(this, PersonQuickCreate, sidebarProps, props, events);
    },
  },
  components: {FamilyTreeCard},
};
</script>

<style lang="scss" scoped>
.familytree-card-container {
  position: absolute;
}
</style>
