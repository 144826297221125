<template>
  <div class="family-tree-lineage-page">
    <mcr-loading-indicator v-if="familyTreeLineageLoadingState" :loading="true"></mcr-loading-indicator>
    <div v-else-if="noLineage" class="readable_content">
      <h1>Family Tree is missing or is private!</h1>
      <p>Sorry, this tree does not exist, or you have no rights to access this tree.</p>
    </div>
    <div v-else class="lineage readable_content">
      <p><router-link :to="ftRoute">&larr; Back to Family Tree</router-link></p>
      <div v-for="(person, index) in familyTreeLineageState" :key="person.object_id" class="lineage-card-wrapper">
        <family-tree-card-container
          :person="person"
          :card-height="60"
          :handle-click-allowed="true"
          :context-menu-sections="contextMenuSections"
          :quick-actions-enabled="false"
          :view-tree-action="viewTreeAction"
        >
          <lineage-card
            slot="card"
            ref="card"
            slot-scope="props"
            v-right-click-outside="props.immediateHideMenu"
            :image-src="person.profile_picture.attachment"
            :birth-date="person.birth_date"
            :death-date="person.death_date"
            :is-deceased="person.is_deceased"
            :full-name="person.full_name"
            :first-names="person.first_names"
            :surnames="person.surnames"
            :gender="getGender(person)"
            :generation-number="person.generation_number"
            @click-data="props.handlePersonClick(person)"
            @contextmenu.native.prevent="props.openContextMenu"
            @mouseenter.native="props.mouseenter"
            @mouseleave.native="props.mouseleave"
          ></lineage-card>
        </family-tree-card-container>
        <div class="connector" v-if="!isLastIndex(index)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFamilyTreeRoute} from '@common/utils/utils.routes';
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import {CONTEXT_MENU_SECTIONS} from '@/components/modules/familyTree/constants';
import FamilyTreeCardContainer from '@/components/modules/familyTree/tree/familyTreeCardContainer';
import LineageCard from '@/components/modules/familyTreeLineage/lineage/LineageCard';

export default {
  metaInfo() {
    const treeName = this.$store.getters.familyTreeNameState;
    const lineage = this.$store.getters.familyTreeLineageState;
    const startPerson = lineage ? lineage[lineage.length - 1] : null;
    const personName = startPerson ? startPerson.full_name : '';
    const title = treeName ? (personName ? `${personName}'s Lineage — ${treeName}` : treeName) : 'Lineage';
    return {
      title: title,
    };
  },
  activated() {
    if (
      this.$route.query.start_person_id !== this.familyTreeLineageStartPersonIdState ||
      !this.familyTreeLineageState
    ) {
      this.init();
    }
  },
  watch: {
    $route: {
      handler: function (toRoute, fromRoute) {
        if (
          toRoute.name === 'familytree-lineage' &&
          fromRoute.name === 'familytree-lineage' &&
          toRoute.query.start_person_id !== fromRoute.query.start_person_id
        ) {
          this.init();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreeLineageState',
      'familyTreeLineageLoadingState',
      'familyTreeLineageStartPersonIdState',
      'familyTreePreferencesState',
    ]),
    contextMenuSections() {
      return [CONTEXT_MENU_SECTIONS.EDIT, CONTEXT_MENU_SECTIONS.ADD_RELATIVE, CONTEXT_MENU_SECTIONS.VIEW_TREE];
    },
    noLineage() {
      return this.error;
    },
    ftRoute() {
      return getFamilyTreeRoute(this.$store);
    },
  },
  methods: {
    isLastIndex(index) {
      return index === this.familyTreeLineageState.length - 1;
    },
    init() {
      this.error = false;
      this.$store.commit('setFamilyTreeLineageStartPersonIdState', this.$route.query.start_person_id);
      const params = {id: this.$route.params.id, start_person_id: this.$route.query.start_person_id};
      this.$store.dispatch('fetchFamilyTreeLineageAction', params).catch(err => {
        this.error = true;
      });
      this.$store.dispatch('fetchFamilyTreeSurnamesListAction', this.$route.params.id || 'my');
      if (isEmpty(this.$store.getters.familyTreePersonOptionsState)) {
        this.$store.dispatch('fetchFamilyTreePersonOptionsAction');
      }
    },
    getGender(person) {
      return this.familyTreePreferencesState.color_code_gender ? person.gender : null;
    },
    viewTreeAction(ftId, personId) {
      const query = {focus_person_id: personId, start_person_id: this.$route.query.start_person_id};
      this.$router.push({name: 'familytree-details', params: {id: ftId}, query});
    },
  },
  components: {
    FamilyTreeCardContainer,
    LineageCard,
  },
};
</script>

<style lang="scss" scoped>
.family-tree-lineage-page {
  .familytree-card-container {
    position: initial;

    &::v-deep .family-tree-card-menu {
      right: 0px;
      left: initial;
    }
  }
  .lineage-card-wrapper {
    margin-bottom: 20px;
    position: relative;
    @media only screen and (max-width: $breakpoint-mobile-se) {
      margin-bottom: 12px;
    }
  }
  .connector {
    position: absolute;
    height: 25px;
    width: 2px;
    background: rgba($connector-line-color, 0.6);
    left: 88px;
    z-index: -1;
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .lineage.readable_content {
      padding: 70px 16px 30px;
    }
  }

  .lineage {
    padding-top: 80px;
    position: relative;
    .lineage-breadcrumbs-container {
    }
  }
}
</style>
