<template>
  <div class="family-tree-zoom">
    <div class="item" @click="$emit('zoom-in')" v-tooltip="inTooltip"><zoom-in-icon :size="24" title="" /></div>
    <div class="item" @click="$emit('zoom-out')" v-tooltip="outTooltip"><zoom-out-icon :size="24" title="" /></div>
  </div>
</template>

<script>
import ZoomOutIcon from 'vue-material-design-icons/MagnifyMinusOutline';
import ZoomInIcon from 'vue-material-design-icons/MagnifyPlusOutline';

export default {
  computed: {
    inTooltip() {
      return {content: 'Zoom In', placement: 'left', delay: {show: 1000}};
    },
    outTooltip() {
      return {content: 'Zoom Out', placement: 'left', delay: {show: 1000}};
    },
  },
  components: {ZoomInIcon, ZoomOutIcon},
  name: 'familyTreeZoom',
};
</script>

<style scoped></style>
