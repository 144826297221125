<template>
  <div class="tab-details tab-content">
    <person-edit-form-block v-if="editMode" @end-edit-mode="setEditMode(false)" ref="editForm"></person-edit-form-block>
    <person-details-block
      v-else
      :show-edit-button-tooltip="showEditButtonTooltip"
      @start-edit-mode="setEditMode(true)"
    ></person-details-block>
    <person-saved-mentions-block
      v-if="familyTreePersonSavedMentionsState.length"
      :person-name="personFullName"
      :editable="familyTreePersonState.is_write_allowed"
      :mentions="familyTreePersonSavedMentionsState"
      :meta="familyTreePersonSavedMentionsMetaState"
      :loading="familyTreePersonSavedMentionsLoadingState"
      @switch-page="reloadMentions"
    ></person-saved-mentions-block>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import {PROFILE_EDIT_HELPER_KEY} from '@/components/modules/familyTree/constants';

import PersonDetailsBlock from './components/PersonDetailsBlock';
import PersonEditFormBlock from './components/PersonEditFormBlock';
import PersonSavedMentionsBlock from './components/PersonSavedMentionsBlock';

export default {
  data() {
    return {
      editMode: Boolean(this.$route.query.edit) && this.$store.getters.familyTreePersonState.is_write_allowed,
      showEditButtonTooltip: false,
    };
  },
  mounted() {
    if (this.editMode) {
      this.$nextTick(() => {
        this.$scrollTo('.person-edit-form-block', {offset: -120});
      });
    }
  },
  computed: {
    ...mapGetters([
      'familyTreePersonState',
      'familyTreePersonPrimaryFullnameEnState',
      'familyTreePersonPrimaryFullnameCnState',
      'familyTreePersonSavedMentionsState',
      'familyTreePersonSavedMentionsMetaState',
      'familyTreePersonSavedMentionsLoadingState',
      'reviewedItemsState',
    ]),
    personFullName() {
      return (
        this.familyTreePersonPrimaryFullnameEnState || this.familyTreePersonPrimaryFullnameEnState || 'this person'
      );
    },
  },
  methods: {
    reloadMentions(page) {
      this.$store.dispatch('fetchFamilyTreePersonSavedMentionsAction', {
        person_id: this.familyTreePersonState.object_id,
        page,
      });
    },
    setEditMode(bool) {
      if (this.showEditButtonTooltip) {
        this.showEditButtonTooltip = false;
        this.$store.commit('addReviewedItemState', PROFILE_EDIT_HELPER_KEY);
      }
      this.editMode = bool;
    },
    activate() {
      if (!this.editMode) {
        this.showEditButtonTooltip = !this.reviewedItemsState.includes(PROFILE_EDIT_HELPER_KEY);
      }
    },
    deactivate() {
      this.showEditButtonTooltip = false;
    },
  },
  components: {PersonEditFormBlock, PersonDetailsBlock, PersonSavedMentionsBlock},
  name: 'TabDetails',
};
</script>

<style lang="scss" scoped></style>
