<template>
  <div class="family-tree-page">
    <template v-if="showTree">
      <keep-alive>
        <family-tree ref="family-tree"></family-tree>
      </keep-alive>
      <mcr-button-router-link v-if="!userIsLoggedInState" class="build-tree-cta" :to="{name: 'familytree-details-my'}"
        >Build My Tree
      </mcr-button-router-link>
      <actions-helper></actions-helper>
    </template>
    <mcr-loading-indicator v-else-if="familyTreeDrawLoadingState" :loading="true" />
    <page-error v-else-if="treeLoadError"></page-error>
    <family-tree-no-access v-else></family-tree-no-access>
  </div>
</template>

<script>
import mcrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import {getTreeImagePreviewLink} from '@common/utils/utils';
import isEmpty from 'lodash/isEmpty';
import {mapActions, mapGetters} from 'vuex';

import ActionsHelper from '@/components/modules/familyTree/actionsHelper';
import FamilyTreeNoAccess from '@/components/modules/familyTree/familyTreeNoAccess';
import PageError from '@/components/page.error';

import {CLAN_SURNAME_QUERY_PARAM} from './constants';
import FamilyTree from './tree/familyTree';

export default {
  metaInfo() {
    const treeName = this.$store.getters.familyTreeNameState;
    const startPerson = this.$store.getters.familyTreeStartPersonState;
    const personName = startPerson ? startPerson.full_name : '';
    const title = treeName ? (personName ? `${personName}'s Tree — ${treeName}` : treeName) : 'Family Tree';
    return {
      title: title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Free family tree builder. Add your family members and start exploring your roots today. Built for - and by - people of Chinese descent.',
        },
        {
          vmid: 'og:image:secure_url',
          property: 'og:image:secure_url',
          content:
            getTreeImagePreviewLink({treeId: this.$route.params.id}) || this.$getAsset('/assets/services/tree_new.png'),
        },
        {
          vmid: 'twitter:image',
          property: 'twitter:image',
          name: 'twitter:image',
          content:
            getTreeImagePreviewLink({treeId: this.$route.params.id}) || this.$getAsset('/assets/services/tree_new.png'),
        },
        {
          vmid: 'twitter:card',
          property: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
      ],
    };
  },
  data() {
    return {
      treeLoadError: false,
    };
  },
  activated() {
    const treeIdChanged = this.familyTreeDetailsIdState && this.$route.params.id != this.familyTreeDetailsIdState;
    const queryStartPersonId = this.$route.query.start_person_id;
    const queryFocusPersonId = this.$route.query.focus_person_id;
    const startPersonIdChanged = queryStartPersonId
      ? this.familyTreeStartPersonIdState != queryStartPersonId
      : this.familyTreeHomePerson.id != this.familyTreeStartPersonIdState;
    if (treeIdChanged || startPersonIdChanged || this.familyTreePersonsStateEmpty) {
      const [familyTreePromise, familyTreeSurnamesPromise] = this.onTreeRouteChange();
      Promise.all([familyTreePromise, familyTreeSurnamesPromise]).then(responses => {
        const surname = this.$route.query[CLAN_SURNAME_QUERY_PARAM];
        if (surname && responses[1].objects.includes(surname)) {
          this.$modal.show(`clan-profile-modal-${surname}`);
        }
      });
      return;
    }
    if (this.$route.query.noshift) {
      if (this.$refs['family-tree']) {
        this.$refs['family-tree'].updateVisibleTreeThrottled();
      }
      return;
    }
    if (this.familyTreePersonsDrawnState || this.familyTreeLineageState) {
      if (queryStartPersonId) {
        this.$store.commit('setFamilyTreeStartPersonIdState', queryStartPersonId);
        this.$store.commit('setFamilyTreeFocusPersonIdState', queryStartPersonId);
      }
      if (queryFocusPersonId) {
        this.$store.commit('setFamilyTreeFocusPersonIdState', queryFocusPersonId);
      }
    }
    if (this.$refs['family-tree']) {
      this.$refs['family-tree'].updateVisibleTreeThrottled();
    }
  },
  watch: {
    $route: {
      handler: function (toRoute, fromRoute) {
        const ftRoutes = ['familytree-details', 'familytree-details-my'];
        const openClanModal = toRoute.query[CLAN_SURNAME_QUERY_PARAM] && !fromRoute.query[CLAN_SURNAME_QUERY_PARAM];
        const closeClanModal = fromRoute.query[CLAN_SURNAME_QUERY_PARAM] && !toRoute.query[CLAN_SURNAME_QUERY_PARAM];
        const switchBetweenTrees = ftRoutes.includes(toRoute.name) && ftRoutes.includes(fromRoute.name);
        if (!switchBetweenTrees || openClanModal || closeClanModal) {
          // if switched tab or opened or closed clan-surname modal, don't reload FT.
          return;
        }
        this.onTreeRouteChange();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'familyTreeDrawLoadingState',
      'familyTreeDetailsIdState',
      'familyTreeLineageState',
      'familyTreePersonsDrawnState',
      'userIsLoggedInState',
      'familyTreeStartPersonIdState',
      'familyTreeHomePerson',
      'familyTreeMapImageState',
    ]),
    familyTreePersonsStateEmpty() {
      return isEmpty(this.familyTreePersonsDrawnState);
    },
    fetchFamilyTreeParams() {
      return {id: this.familyTreId, start_person_id: this.$route.query.start_person_id};
    },
    familyTreId() {
      return this.$route.params.id;
    },
    showTree() {
      const routeTreeId = parseInt(this.$route.params.id);
      const stateTreeId = this.familyTreeDetailsIdState && parseInt(this.familyTreeDetailsIdState);
      return !this.familyTreePersonsStateEmpty && (routeTreeId === stateTreeId || isNaN(routeTreeId));
    },
  },
  methods: {
    ...mapActions([
      'fetchFamilyTreeAction',
      'fetchFamilyTreeSurnamesListAction',
      'fetchFamilyTreeHintsPersonIdsAction',
      'fetchFamilyTreeMapAction',
    ]),
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    onTreeRouteChange() {
      this.treeLoadError = false;
      const focusPerson = this.$route.query.focus_person_id || this.$route.query.start_person_id || null;

      this.$store.commit('setFamilyTreeMapImageState', null);
      this.$store.commit('setFamilyTreeFocusPersonIdState', focusPerson);
      this.$store.commit('setFamilyTreeLineageState', null);
      this.$store.commit('setFamilyTreePersonsSearchListState', null);
      this.$store.commit('setFamilyTreePersonsSearchListMetaState', null);
      this.$store.commit('setFamilyTreePersonsDrawnState', null);
      this.$store.commit('setFamilyTreeLinesDrawnState', null);

      this.fetchFamilyTreeHintsPersonIdsAction({family_tree_id: this.familyTreId || 'my'});
      const familyTreePromise = this.fetchFamilyTreeAction(this.fetchFamilyTreeParams).catch(error => {
        if (error && error.response && error.response.status === 500) {
          this.treeLoadError = true;
        }
      });
      const familyTreeSurnamesPromise = this.fetchFamilyTreeSurnamesListAction(this.familyTreId || 'my');
      if (isEmpty(this.$store.getters.familyTreePersonOptionsState)) {
        this.$store.dispatch('fetchFamilyTreePersonOptionsAction');
      }

      return [familyTreePromise, familyTreeSurnamesPromise];
    },
  },
  components: {FamilyTreeNoAccess, ActionsHelper, FamilyTree, mcrButtonRouterLink, PageError},
  name: 'familytree-page',
};
</script>

<style lang="scss" scoped>
.family-tree-page {
  display: flex;
  flex-direction: column;

  .mcr-loading-indicator {
    height: calc(100vh - #{$main-menu-height});
    margin: 0;

    @media only screen and (max-width: $main-menu-breakpoint) {
      height: calc(100vh - #{$main-menu-height-mobile});
    }
  }

  .build-tree-cta {
    position: fixed;
    z-index: calc(#{$modal-z-index} - 1);
    left: 16px;
    bottom: 16px;
  }

  .family-tree-actions-helper {
    position: fixed;
    left: 16px;
    bottom: 16px;
    align-items: center;
  }

  .build-tree-cta + .family-tree-actions-helper {
    left: 170px;
    bottom: 24px;
  }
}
</style>
