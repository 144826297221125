<template>
  <div class="edit-spouse-meta-modal-content with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Edit Relationship with {{ name }}</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div class="content">
      <marriage-details-block
        :initial-data="initialData"
        :family-tree-id="familyTreeId"
        ref="form"
      ></marriage-details-block>
    </div>
    <div class="action-buttons right-align">
      <mcr-button class="white" :disabled="loading" @click="closeModal">Cancel</mcr-button>
      <mcr-button class="save" :loading="loading" @click="onSave">Save Changes</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import CloseIcon from 'vue-material-design-icons/Close';

import MarriageDetailsBlock from '@/components/modules/familyTree/tree/modals/fields/MarriageDetailsBlock';

export default {
  props: {
    name: String,
    initialData: Object,
    familyTreeId: [String, Number],
    coupleId: Number,
    couplePersonIds: Array,
  },
  computed: {
    loading() {
      return this.$store.getters.coupleUpdateLoadingState;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    onSave() {
      const data = this.$refs.form.getDataForSave();
      this.$store.dispatch('updateCoupleAction', {id: this.coupleId, ...data}).then(() => {
        this.$toasted.success('Relationship updated.');
        this.$store.commit('mutateMetaOfPersonRelativesState', {couple_id: this.coupleId, ...data});
        this.$store.commit('mutateFamilyTreeLinesDrawnState', {
          relatedPeople: this.couplePersonIds,
          newData: {is_dashed: data.is_divorced},
        });
        this.closeModal();
      });
    },
  },
  components: {CloseIcon, MarriageDetailsBlock, McrButton},
  name: 'editSpouseMetaModalContent',
};
</script>

<style lang="scss" scoped>
.edit-spouse-meta-modal-content {
  .save {
    min-width: 128px;
  }
  @media only screen and (min-width: $breakpoint-mobile) {
    width: 720px;
  }
}
</style>
