<template>
  <div class="tab-library tab-content">
    <library-block v-if="displayLibrary"></library-block>
  </div>
</template>

<script>
import LibraryBlock from './components/LibraryBlock';

export default {
  data() {
    return {
      displayLibrary: false,
    };
  },
  methods: {
    activate() {
      this.displayLibrary = true;
    },
  },
  components: {LibraryBlock},
  name: 'TabLibrary',
};
</script>

<style scoped></style>
