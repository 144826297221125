<template>
  <images-gallery-overlay-container
    :allow-touch="!areMobileDetailsShown"
    @click-close="cropCancel"
    @hide-image="hideDetails"
    @display-item-changed="displayItemChanged"
    @image-loaded="onImageLoad"
  >
    <div class="info-bar-content" slot="info-bar" slot-scope="props">
      <h5>Photos of {{ currentPersonFullName }}</h5>
      <p class="photo-count">{{ props.currentIndex + 1 }} of {{ props.itemsCount }} photos</p>
      <div class="info-bar-actions" v-if="areDetailsShown(props.item.object_id)">
        <a
          v-if="canMakeProfilePicture(props.item)"
          class="make-profile-picture"
          @click="makeProfilePictureClick(props.item)"
        >
          <account-box-multiple-outline-icon></account-box-multiple-outline-icon> <span>Set Profile</span>
        </a>
        <a v-if="canRemovePicture(props.item)" class="remove-picture" @click="removePictureClick(props.item)">
          <delete-icon></delete-icon> <span>Delete</span>
        </a>
        <asset-download-button
          v-if="isValidImage(props.item)"
          :href="props.item.attachment"
          :filename="familyTreeAssetDetailsByIdState[props.item.object_id].filename"
        ></asset-download-button>
        <a class="toggle-details" @click="toggleDetails"
          ><pencil-icon></pencil-icon> <span>{{ toggleDetailsText }}</span></a
        >
      </div>

      <asset-details-toggle v-if="areDetailsShown(props.item.object_id)" :show-mobile="areMobileDetailsShown">
        <div v-if="isEditAllowed" class="edit-form">
          <asset-edit-form-container
            :asset="familyTreeAssetDetailsByIdState[props.item.object_id]"
          ></asset-edit-form-container>
          <asset-upload-meta
            v-if="props.item.object_id"
            :asset="familyTreeAssetDetailsByIdState[props.item.object_id]"
          ></asset-upload-meta>
        </div>

        <div v-else class="readonly">
          <hr />
          <p v-html="getTaggedPersonsReadonlyHtml(props.item.object_id)"></p>
          <p v-html="getPlaceDateReadonlyHtml(props.item.object_id)"></p>
          <p class="file-description">{{ familyTreeAssetDetailsByIdState[props.item.object_id].description }}</p>
          <asset-upload-meta :asset="familyTreeAssetDetailsByIdState[props.item.object_id]"></asset-upload-meta>
        </div>
      </asset-details-toggle>
    </div>
    <template slot="modals" slot-scope="props">
      <modal :name="profilePictureCropModalName" class="profile-picture-crop-modal" classes="clear_modal">
        <person-profile-picture-crop-modal-content-container
          :person-id="personId"
          :asset-id="props.item.object_id"
          @close="cropCancel"
        ></person-profile-picture-crop-modal-content-container>
      </modal>
      <modal :name="profilePictureDeleteModalName" classes="clear_modal">
        <delete-confirm-modal-content
          :modal-name="profilePictureDeleteModalName"
          :loading="familyTreePersonDeleteAssetLoadingState"
          @confirm="deleteAsset(props.item)"
        >
          <h5>Are you sure you want to delete this photo?</h5>
        </delete-confirm-modal-content>
      </modal>
    </template>
  </images-gallery-overlay-container>
</template>

<script>
import imagesGalleryOverlayContainer from '@common/elements/gallery/imagesGalleryOverlayContainer';
import {preload} from '@common/utils/utils.sources';
import AccountBoxMultipleOutlineIcon from 'vue-material-design-icons/AccountBoxMultipleOutline';
import DeleteIcon from 'vue-material-design-icons/Delete';
import PencilIcon from 'vue-material-design-icons/Pencil';
import {mapGetters} from 'vuex';

import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

import {isImageFile} from '@/components/common/filePreviewHelper';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

import AssetDetailsToggle from '../../../familyTreeLibrary/common/AssetDetailsToggle';
import AssetDownloadButton from '../../../familyTreeLibrary/common/AssetDownloadButton';
import AssetEditFormContainer from '../../../familyTreeLibrary/common/AssetEditFormContainer';
import AssetUploadMeta from '../../../familyTreeLibrary/common/AssetUploadMeta';
import personProfilePictureCropModalContentContainer from './personProfilePictureCropModalContentContainer';

export default {
  computed: {
    ...mapGetters([
      'galleryDisplayItemState',
      'familyTreePersonProfilePictureLoadingState',
      'familyTreePersonDeleteAssetLoadingState',
      'familyTreeAssetDetailsLoadingState',
      'familyTreeAssetDetailsByIdState',
    ]),
    currentPersonFullName() {
      return this.galleryDisplayItemState.personNameEn || this.galleryDisplayItemState.personNameCn
        ? `${this.galleryDisplayItemState.personNameEn || ''} ${this.galleryDisplayItemState.personNameCn || ''}`
        : UNKNOWN_NAME;
    },
    profilePictureCropModalName() {
      return 'profile-picture-crop-modal';
    },
    isEditAllowed() {
      return this.galleryDisplayItemState.editable;
    },
    personId() {
      return this.galleryDisplayItemState.personId;
    },
    toggleDetailsText() {
      return this.areMobileDetailsShown ? 'Hide Details' : 'Details';
    },
  },
  data() {
    return {
      cropModalRef: 'crop-modal',
      profilePictureDeleteModalName: 'profile-picture-delete-modal',
      areMobileDetailsShown: false,
      areDetailsHidden: false,
    };
  },
  methods: {
    canMakeProfilePicture(item) {
      return this.isValidImage(item) && isImageFile(item.filename) && this.isEditAllowed;
    },
    canRemovePicture(item) {
      return this.isValidImage(item) && this.isEditAllowed;
    },
    isValidImage(item) {
      return !item.error && !item.uploading;
    },
    makeProfilePictureClick(item) {
      this.$modal.show(this.profilePictureCropModalName);
    },
    removePictureClick(item) {
      this.$modal.show(this.profilePictureDeleteModalName);
    },
    cropCancel() {
      this.$modal.hide(this.profilePictureCropModalName);
    },
    deleteAsset(item) {
      if (item.object_id) {
        this.$store
          .dispatch('deletePersonAssetAction', {
            assetId: item.object_id,
            personId: this.personId,
          })
          .then(() => {
            this.$modal.hide(this.profilePictureDeleteModalName);
          });
      }
    },
    getAssetDetails(assetId) {
      this.$store.dispatch('getAssetDetailsAction', {assetId});
    },
    getTaggedPersonsReadonlyHtml(assetId) {
      const asset = assetId ? this.familyTreeAssetDetailsByIdState[assetId] : null;
      const persons = asset && asset.persons ? asset.persons : [];
      let str = '';
      for (let i = 0; i < persons.length; i++) {
        const separator = i === persons.length - 1 ? ' and ' : ', ';
        const personHtml = `<strong>${persons[i].full_name || UNKNOWN_NAME}</strong>`;
        str = str ? `${str}${separator}${personHtml}` : personHtml;
      }
      return str;
    },
    getPlaceDateReadonlyHtml(assetId) {
      const asset = assetId ? this.familyTreeAssetDetailsByIdState[assetId] : null;
      const location = asset.location || (asset.location_tag ? asset.location_tag.display_text : '');
      const dateLocation = [asset.event_date, location];
      return dateLocation
        .filter(str => str)
        .map(str => `<strong>${str}</strong>`)
        .join(' in ');
    },
    toggleDetails() {
      this.areMobileDetailsShown = !this.areMobileDetailsShown;
    },
    areDetailsShown(objectId) {
      return !this.areDetailsHidden && objectId && this.familyTreeAssetDetailsByIdState[objectId];
    },
    hideDetails() {
      this.areDetailsHidden = true;
    },
    displayItemChanged(newItem) {
      this.areDetailsHidden = false;
      this.cropCancel();
      if (newItem.object_id) {
        this.getAssetDetails(newItem.object_id);
      }
    },
    onImageLoad() {
      if (this.$store.getters.galleryHasNextItemState) {
        const src = this.$store.getters.galleryItemsState[this.$store.getters.galleryCurrentIndexState + 1].src;
        preload([src]);
      }
    },
  },
  components: {
    AssetDownloadButton,
    personProfilePictureCropModalContentContainer,
    AssetEditFormContainer,
    DeleteConfirmModalContent,
    AssetUploadMeta,
    AssetDetailsToggle,
    imagesGalleryOverlayContainer,
    AccountBoxMultipleOutlineIcon,
    DeleteIcon,
    PencilIcon,
  },
};
</script>

<style lang="scss" scoped>
@import '@common/style/gallery.info';
</style>
