import consts from '@/base/utils/consts';

import {RELATION_TYPE_NAMES, SPOUSE_RELATION_TYPE} from '@/components/modules/familyTree/constants';
import personCreateModalContent from '@/components/modules/familyTree/tree/modals/personCreateModalContent';
import OnboardingWizard from '@/components/modules/familyTree/tree/modals/wizard/OnboardingWizard';
import {
  createGhostParentHandler,
  createRelativeHandler,
  getNewPersonGender,
  getNewPersonGenerationNumberByRelationType,
  getNewPersonHierarchyLevelByRelationType,
  getPossibleChildrenList,
  getPossibleParentsList,
} from '@/components/modules/familyTree/tree/services';

const MODAL_NAME_QUICK_SIDEBAR = consts.MODAL_NAME_QUICK_SIDEBAR;
const MODAL_NAME_PERSON_CREATE = 'person-create';

export function openQuickSidebar(vm, innerComponent, sidebarProps, props, events) {
  if (!sidebarProps || !sidebarProps.id) {
    throw 'sidebar.id is required to openQuickSidebar';
  }
  if (vm.$store.getters.quickSidebarIdState === sidebarProps.id) {
    return;
  }
  vm.$modal.hide(MODAL_NAME_QUICK_SIDEBAR);
  vm.$store.commit('setQuickSidebarPropsState', sidebarProps);
  const sidebarClasses = sidebarProps.lightBackground ? ' light' : '';
  let modalParams = {
    name: MODAL_NAME_QUICK_SIDEBAR,
    clickToClose: true,
    class: 'quick_sidebar' + sidebarClasses,
    overlayTransition: 'info-bar-slide-left',
    transition: 'info-bar-slide-left',
  };
  let modalEvents = events || {};
  modalEvents.closed = () => {
    if (vm.$store.getters.quickSidebarIdState === sidebarProps.id) {
      vm.$store.commit('setQuickSidebarPropsState', null);
    }
  };

  vm.$modal.show(innerComponent, props, modalParams, modalEvents);
}

export function closeQuickSidebar(vm) {
  vm.$modal.hide(MODAL_NAME_QUICK_SIDEBAR);
}

export function isQuickSidebarHotkeyDisabled() {
  /* hotkeys are disabled when other modal is opened on top of a sidebar, e.g. delete confirmation */
  const el = document.getElementById('modals-container');
  for (let child of el.children) {
    if (child.dataset.modal !== MODAL_NAME_QUICK_SIDEBAR) {
      return true;
    }
  }
  return false;
}

export function openCreateGhostParentModal(personData, child, possibleSiblings, vm) {
  let siblingIds = possibleSiblings.filter(option => option.selected).map(option => option.id);
  let props = {
    initialData: personData,
    relationType: personData.gender === 'm' ? 'father' : personData.gender === 'f' ? 'mother' : 'parent',
    fullName: vm.$store.getters.familyTreePersonsByIdState[child.object_id].full_name,
    showMarriageDetails: false,
    saveClickHandler: async (formData, marriageData) => {
      await createGhostParentHandler(formData, child, siblingIds, true, vm);
      vm.$modal.hide(MODAL_NAME_PERSON_CREATE);
    },
    possibleChildrenList: possibleSiblings,
    possibleParentsList: [],
    childrenIdsChange: value => {
      siblingIds = value;
    },
    parentsIdsChange: value => {},
  };
  let modalParams = {
    classes: 'clear_modal white_modal',
    name: MODAL_NAME_PERSON_CREATE,
    scrollable: true,
    height: 'auto',
    clickToClose: false,
  };

  vm.$modal.show(personCreateModalContent, props, modalParams);
}

export function openAddPersonModal(
  initialData,
  relationType,
  person,
  treeId,
  modalName,
  openDetails,
  refetchTree,
  callback,
  vm
) {
  const possibleParentsList = getPossibleParentsList(relationType, person, vm);
  const possibleChildrenList = getPossibleChildrenList(relationType, person, vm);
  let childrenIds = possibleChildrenList.filter(option => option.selected).map(option => option.id);
  let parentsIds = possibleParentsList.find(option => option.selected);
  parentsIds = parentsIds ? parentsIds.id : [];

  initialData = initialData || {
    gender: getNewPersonGender(relationType, person, vm),
    hierarchy_level: getNewPersonHierarchyLevelByRelationType(person.hierarchy_level, relationType),
    generation_number: getNewPersonGenerationNumberByRelationType(person.generation_number, relationType),
    family_tree_id: treeId,
  };
  let props = {
    relationType: RELATION_TYPE_NAMES[relationType],
    fullName: person.full_name,
    initialData: initialData,
    showMarriageDetails: relationType === SPOUSE_RELATION_TYPE,
    saveClickHandler: async (formData, marriageData) => {
      await createRelativeHandler(
        formData,
        relationType,
        person,
        {marriage_data: marriageData},
        childrenIds,
        parentsIds,
        openDetails,
        refetchTree,
        vm
      );
      callback(formData);
    },
    possibleChildrenList: possibleChildrenList,
    possibleParentsList: possibleParentsList,
    childrenIdsChange: value => {
      childrenIds = value;
    },
    parentsIdsChange: value => {
      parentsIds = value;
    },
  };
  let modalParams = {
    classes: 'clear_modal white_modal',
    name: modalName,
    scrollable: true,
    height: 'auto',
    clickToClose: false,
  };
  vm.$modal.show(personCreateModalContent, props, modalParams);
}

export function autoOpenWizardSidebar(vm, treeId) {
  if (!treeId || isNaN(parseInt(treeId)) || vm.$store.getters.userFamilyTreeIdState == null) {
    return;
  }
  treeId = treeId.toString();
  const userTreeId = vm.$store.getters.userFamilyTreeIdState.toString();
  const startPerson = vm.$store.getters.familyTreeStartPersonState;
  const isTreeRoute = vm.$route.name === 'familytree-details';
  const wizard = vm.$store.getters.onboardingWizardState[treeId];
  if (
    wizard &&
    wizard.requireReview &&
    treeId === userTreeId &&
    isTreeRoute &&
    startPerson &&
    startPerson.is_current_user
  ) {
    openWizardSidebar(vm, treeId.toString(), true);
  }
}
export function openWizardSidebar(vm, treeId, isAutoOpen) {
  const props = {
    treeId: treeId,
    savedState: vm.$store.getters.onboardingWizardSavedState[treeId] || {},
    currentState: vm.$store.getters.onboardingWizardState[treeId] || {},
    isAutoOpen: isAutoOpen,
  };
  const events = {
    opened: () => {
      vm.$store.commit('updateOnboardingWizardState', {treeId: treeId, data: {requireReview: false}});
    },
  };
  openQuickSidebar(vm, OnboardingWizard, {id: 'onboarding-wizard', lightBackground: true}, props, events);
}
