<template>
  <div class="person-saved-mentions" v-if="familyTreePersonSavedMentionsLoadingState">
    <div class="heading">
      <h5>{{ title }}</h5>
    </div>
    <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
  </div>
  <div class="person-saved-mentions" v-else-if="totalCount">
    <div class="heading">
      <h5>{{ title }}</h5>
    </div>

    <div v-for="record in familyTreePersonSavedMentionsState" :key="record.id" class="record-item">
      <div class="record-details">
        <div>
          <router-link :to="getDetailsRoute(record)"> Source: {{ getRecordTitle(record) }} </router-link>
        </div>
        <record-type :type="record.source.mention_type_display" :type-name="getSubtitle(record)"></record-type>
      </div>
      <div class="actions" v-if="editable">
        <remove-field-icon class="action" @click.native="removeClick(record)"></remove-field-icon>
      </div>
    </div>
    <base-pagination
      :meta="familyTreePersonSavedMentionsMetaState"
      :show-jump-to="false"
      @onSwitchPage="onSwitchPage"
    ></base-pagination>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import RecordType from '@common/elements/icons/RecordType';
import {getPersonRecordDetailRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

import ConfirmDeleteMention from '@/components/common/modals/ConfirmDeleteMention';

import RemoveFieldIcon from '@/components/modules/familyTree/tree/modals/fields/removeFieldIcon';

export default {
  props: {
    editable: Boolean,
    personId: Number,
    personName: String,
  },
  computed: {
    ...mapGetters([
      'familyTreePersonSavedMentionsState',
      'familyTreePersonSavedMentionsMetaState',
      'familyTreePersonSavedMentionsLoadingState',
    ]),
    totalCount() {
      return this.familyTreePersonSavedMentionsMetaState.total_count;
    },
    title() {
      return this.totalCount === 1
        ? `${this.totalCount} Associated Record`
        : `${this.totalCount || ''} Associated Records`;
    },
  },
  methods: {
    onSwitchPage(page) {
      this.$store.dispatch('fetchFamilyTreePersonSavedMentionsAction', {person_id: this.personId, page});
    },
    getRecordTitle(record) {
      return record.source.title_en || record.source.title_ch;
    },
    getSubtitle(record) {
      const country = record.source.country;
      const type = record.source.mention_type_display;
      return country && type ? `${country}, ${type}` : country || type;
    },
    getDetailsRoute(record) {
      return getPersonRecordDetailRoute(record.mention_person.code, record.mention_person.full_name);
    },
    removeClick(record) {
      const props = {
        recordId: record.id,
        recordName: this.getRecordTitle(record),
        personName: this.personName,
        reloadRecords: this.onSwitchPage,
      };
      const modalParams = {classes: 'clear_modal'};
      this.$modal.show(ConfirmDeleteMention, props, modalParams);
    },
  },
  name: 'personSavedRecords',
  components: {BasePagination, RecordType, RemoveFieldIcon},
};
</script>

<style lang="scss" scoped>
@import '../tree-modal.scss';

.record-item {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .record-details {
    word-break: break-word;
  }

  &:hover,
  &:focus-within {
    .remove {
      visibility: visible;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .actions .action {
      margin-top: 3px;
    }

    .remove::v-deep {
      .icon-label {
        display: none;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-mobile) {
    .actions {
      min-width: 100px;
    }

    &:hover,
    &:focus-within {
      .remove::v-deep {
        .icon-label {
          visibility: visible;
          display: block;
        }
      }
    }
  }
}
</style>
