<template>
  <person-profile-picture-crop
    :initial-image-src="profileCropImageUrlState"
    :loading="profileCropImageLoadingState"
    :save-loading="familyTreePersonProfilePictureLoadingState"
    @cropped="imageCropped"
    @close="cropCancel"
  ></person-profile-picture-crop>
</template>

<script>
import {mapGetters} from 'vuex';

import PersonProfilePictureCrop from '@/components/common/tree/personProfilePictureCrop';

export default {
  props: {
    personId: {type: [String, Number]},
    assetId: String,
  },
  mounted() {
    this.$store.dispatch('getProfileCropImageUrl', {assetId: this.assetId});
  },
  data() {
    return {
      modalName: 'profile-picture-crop-modal',
    };
  },
  computed: {
    ...mapGetters([
      'profileCropImageLoadingState',
      'profileCropImageUrlState',
      'familyTreePersonProfilePictureLoadingState',
    ]),
  },
  methods: {
    cropCancel() {
      this.$emit('close');
    },
    imageCropped(blob) {
      const data = {personId: this.personId, assetId: this.assetId, file: blob};
      this.$store.dispatch('setFamilyTreePersonProfilePictureAction', data).then(() => {
        this.cropCancel();
      });
    },
  },
  components: {PersonProfilePictureCrop},
};
</script>

<style scoped></style>
