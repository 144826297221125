<template>
  <modal
    :name="modalName"
    classes="clear_modal"
    :scrollable="true"
    height="auto"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="modal-content family-tree-details-modal">
      <close-button @click="closeModal"></close-button>
      <mcr-loading-indicator v-if="loading" :loading="true"></mcr-loading-indicator>
      <transition-expand>
        <div class="clan-profile-content" v-if="!loading">
          <div class="tree-modal-header">
            <div class="name-titles">
              <h4>{{ surnameDisplay }} Family</h4>
              <div class="strapline">{{ spellingsFromTree }}</div>
            </div>
          </div>

          <h5>Family Tree Book</h5>
          <div class="clan-zupu-hints">
            <div class="zupu-intro">
              <div class="bold">What's a Family Tree Book?</div>
              <p>The zupu, jiapu, or family history book, is the Holy Grail of Chinese genealogy.</p>
              <p>
                Traditionally, every Chinese clan had its own family history book, sometimes going back 1000 years or
                more.
              </p>
              <p>
                They often contain family trees, migration stories, biographies of famous ancestors, even guidelines for
                naming each generation of children.
              </p>
              <p>Browse the matches to find your book and traverse the memories of your clan’s past!</p>
              <mcr-button-router-link-to-pr label="Help me find my zupu"></mcr-button-router-link-to-pr>
            </div>

            <div class="record-matches">
              <clan-profile-zupus-match
                v-for="metaObject in zupusWithPlaceMeta"
                :key="metaObject.place_id"
                :surname="surname"
                :clan-id="clanId"
                :place-id="metaObject.place_id"
                :place-name="metaObject.place_name"
                :zupus-count="metaObject.zupus_count"
              ></clan-profile-zupus-match>

              <clan-profile-zupus-match
                v-if="zupusWithPlaceMeta.length === 0 && allZupusMeta.zupus_count"
                :surname="surname"
                :clan-id="clanId"
                :zupus-count="allZupusMeta.zupus_count"
              ></clan-profile-zupus-match>

              <div v-if="!allZupusMeta.zupus_count">Sorry, we couldn't find any {{ surname }} records.</div>
            </div>
          </div>

          <h5 v-if="ancestralHomes.length || otherLocations.length">Locations</h5>
          <div class="clan-locations">
            <div class="sub-label" v-if="ancestralHomes.length">Ancestral Homes</div>
            <router-link
              v-for="location in ancestralHomes"
              :to="getLocationRoute(location.place_id)"
              :key="location.place_id"
              class="clan-location"
              target="_blank"
            >
              {{ location.display_text }}
            </router-link>

            <div class="sub-label" v-if="otherLocations.length">Other Locations</div>
            <span class="clan-location" v-for="location in otherLocations" :key="location.display_text">{{
              location.display_text
            }}</span>
          </div>

          <h5>{{ personsTitle }}</h5>
          <div class="clan-members">
            <clan-profile-member
              v-for="person in persons"
              :full-name="person.full_name"
              :profile-picture="person.profile_picture"
              :gender="person.gender"
              :key="person.object_id"
            />
          </div>
          <div class="surname-info" v-if="clan">
            <h5>Surname Information</h5>
            <router-link :to="{name: 'search-tool-surname-detail', query: {word: surname}}" target="_blank"
              >Learn more about the {{ surname }} surname</router-link
            >
          </div>
        </div>
      </transition-expand>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import {isChineseText} from '@common/utils/utils';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {mapGetters} from 'vuex';

import TransitionExpand from '@/base/transitions/TransitionExpand';

import {CLAN_SURNAME_QUERY_PARAM} from '../../../constants';
import clanProfileMember from './ClanProfileMember';
import ClanProfileZupusMatch from './ClanProfileZupusMatch';

export default {
  name: 'ClanProfileModal',
  props: {
    surname: String,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['familyTreeDetailsIdState', 'familyTreePersonsBySurnameState', 'clanPedigreesMetaListState']),
    modalName() {
      return `clan-profile-modal-${this.surname}`;
    },
    persons() {
      return this.familyTreePersonsBySurnameState[this.surname] || [];
    },
    personsBirthDeathLocations() {
      let locations = [];
      this.persons.forEach(person => {
        if (person.birth_location && !locations.find(existing => isEqual(existing, person.birth_location))) {
          locations.push(person.birth_location);
        }
        if (person.death_location && !locations.find(existing => isEqual(existing, person.death_location))) {
          locations.push(person.death_location);
        }
      });
      return locations;
    },
    ancestralHomes() {
      return this.personsBirthDeathLocations.filter(location => location.place_id);
    },
    otherLocations() {
      return this.personsBirthDeathLocations.filter(location => !location.place_id);
    },
    spellingsFromTree() {
      const surnames = this.persons
        .map(p => p.surnames.map(s => this.capitalize(s.value)).filter(surname => !isChineseText(surname)))
        .flat();
      return [...new Set(surnames)].join(', ');
    },
    personsTitle() {
      return this.persons.length > 1 ? `${this.persons.length} Members` : `${this.persons.length} Member`;
    },
    clan() {
      const clans = this.$store.getters.clansSearchState;
      return clans.length ? clans[0] : null;
    },
    clanId() {
      return this.clan ? this.clan.object_id : null;
    },
    zupusWithPlaceMeta() {
      return this.clanPedigreesMetaListState.filter(zupu => !!zupu.place_id && zupu.zupus_count);
    },
    allZupusMeta() {
      return this.clanPedigreesMetaListState.find(zupu => !zupu.place_id) || {};
    },
    surnameDisplay() {
      if (this.clan) {
        const traditional = this.clan.name_hant && this.clan.name_hant !== this.clan.name_ch ? this.clan.name_hant : '';
        return traditional ? `${this.clan.name_ch} / ${traditional}` : this.clan.name_ch;
      }
      return this.surname;
    },
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen() {
      if (this.$route.query[CLAN_SURNAME_QUERY_PARAM] !== this.surname) {
        this.$router.push({query: {...this.$route.query, [CLAN_SURNAME_QUERY_PARAM]: this.surname}});
      }
      this.setLoading(true);
      const personsPromise = this.$store.dispatch('fetchFamilyTreePersonsBySurnameListAction', {
        familyTreeId: this.familyTreeDetailsIdState,
        surname: this.surname,
      });
      const clanPromise = this.$store.dispatch('searchClansAction', {q: this.surname});
      Promise.all([personsPromise, clanPromise]).then(() => {
        if (this.clan) {
          const placeIds = this.ancestralHomes.map(location => location.place_id);
          this.$store
            .dispatch('fetchClanPedigreesMetaListAction', {clan_id: this.clan.object_id, place_ids: placeIds})
            .then(() => {
              this.setLoading(false);
            });
        } else {
          this.setLoading(false);
        }
      });
    },
    closed() {
      const query = omit(this.$route.query, CLAN_SURNAME_QUERY_PARAM);
      this.$router.push({query});
    },
    getLocationRoute(placeId) {
      return getPlaceDetailRoute(placeId);
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
  components: {
    ClanProfileZupusMatch,
    CloseButton,
    TransitionExpand,
    McrButtonRouterLinkToPr,
    clanProfileMember,
  },
};
</script>

<style lang="scss" scoped>
@import '../tree-modal.scss';

.family-tree-details-modal {
  .tree-modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .clan-zupu-hints {
    display: flex;
    flex-direction: column-reverse;

    .zupu-intro {
      padding: 20px;
      border-radius: 4px;
      border: 1px dashed $mcr-description-border;
      background-color: $mcr-description-background;
      color: #444;
      font-size: 0.9em;
      ul {
      }
    }

    .record-matches {
      margin-top: 6px;
      margin-bottom: 18px;
    }
  }

  .clan-locations {
    .clan-location {
      display: block;
    }
  }

  .sub-label {
    font-weight: bold;
    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  .clan-members {
    display: flex;
    flex-wrap: wrap;

    .clan-profile-member {
      margin-bottom: 16px;
      margin-right: 12px;
      @media only screen and (min-width: $breakpoint-phablet) {
        width: calc(33% - 12px);
        &:nth-child(3) {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: $breakpoint-phablet) and (min-width: 540px) {
        width: calc(50% - 8px);
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: 539px) {
        width: 100%;
      }
    }
    &::after {
      content: '';
      flex: auto;
    }
  }
}
</style>
