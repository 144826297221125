<template>
  <div
    class="person-quick-create"
    @dragenter.stop.prevent
    @dragover.stop.prevent="onDragover"
    @dragleave.self="onDragleave"
    @drop.stop.prevent="onDrop"
  >
    <close-button @click="$emit('close')"></close-button>
    <transition name="fade">
      <drag-drop-prompt v-show="isDragover"></drag-drop-prompt>
    </transition>
    <div class="heading">
      Editing <b>{{ fullName }}</b>
    </div>

    <person-quick-form
      ref="form"
      :form-initial-data="initialData"
      :gender-options="familyTreePersonOptionsState.genders"
      @keyup.enter.native="save"
    >
      <div slot="extra-fields">
        <edit-picture ref="picture" :profile-picture="initialData.profile_picture"></edit-picture>
      </div>
    </person-quick-form>

    <div class="action-buttons">
      <a class="more-options-link" @click="openFullForm">More options</a>
      <mcr-button class="more-options-button dark-grey" :disabled="formLoading" @click="openFullForm"
        >More options</mcr-button
      >
      <mcr-button class="save-button" :loading="formLoading" @click="save">Save</mcr-button>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import McrButton from '@common/elements/buttons/mcrButton';
import DragDropPrompt from '@common/elements/layouts/DragDropPrompt';
import {mapGetters} from 'vuex';

import EditPicture from '@/components/modules/familyTree/tree/modals/quick/EditPicture';
import PersonQuickForm from '@/components/modules/familyTree/tree/modals/quick/PersonQuickForm';

export default {
  props: {
    fullName: String,
    initialData: Object,
    saveClickHandler: Function,
    fullFormOpener: Function,
  },
  data() {
    return {
      isDragover: false,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreePersonUpdateLoadingState',
      'familyTreePersonProfilePictureLoadingState',
      'familyTreePersonOptionsState',
      'familyTreePhotosByPersonIdState',
    ]),
    formLoading() {
      const personPhotos = this.familyTreePhotosByPersonIdState[this.initialData.id];
      const photoUpload = personPhotos && personPhotos[0] && personPhotos[0].uploading;
      return this.familyTreePersonUpdateLoadingState || this.familyTreePersonProfilePictureLoadingState || photoUpload;
    },
  },
  methods: {
    save() {
      const personData = this.$refs.form.getPersonForSave();
      const profilePictureData = this.$refs.picture.getDataForSave();
      this.saveClickHandler(personData, profilePictureData);
    },
    openFullForm() {
      const personData = this.$refs.form.getPersonForSave();
      this.$emit('close');
      this.fullFormOpener(personData);
    },
    onDragover(e) {
      this.isDragover = true;
    },
    onDragleave(e) {
      this.isDragover = false;
    },
    onDrop(e) {
      this.isDragover = false;
      let fileList = e.dataTransfer.files;
      if (fileList.length > 0) {
        this.$refs.picture.filesSelected(fileList);
      }
    },
  },
  components: {DragDropPrompt, EditPicture, McrButton, PersonQuickForm, CloseButton},
};
</script>

<style lang="scss" scoped>
.person-quick-create {
  padding: 26px 24px 100px;
  position: relative;

  .heading {
    font-size: 21px;
    margin-bottom: 24px;
    padding-bottom: 15px;
    border-bottom: 1px solid $divider-line-color;
  }
  .extra-select {
    margin-bottom: 30px;
  }

  .action-buttons {
    display: flex;
    align-items: center;
    background: $off-white;

    .save-button {
      margin-left: 25px;
      flex-grow: 1;
    }
    .more-options-button {
      display: none;
    }
  }

  @media only screen and (max-width: $quick-sidebar-breakpoint) {
    padding: 26px 16px 166px;
    .action-buttons {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 1;
      .mcr-button {
        border-radius: 0;
      }
      .save-button {
        margin-left: 0;
      }
      .more-options-link {
        display: none;
      }
      .more-options-button {
        display: flex;
      }
    }
  }

  @media only screen and (min-width: $quick-sidebar-breakpoint) {
    width: $quick-sidebar-width;

    .action-buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 16px 24px;
      width: $quick-sidebar-width;
      border-top: 1px solid $neutral-200;
      box-shadow: $slight-shadow;
      z-index: 2;
    }
  }
}
</style>
