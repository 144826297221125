<template>
  <div
    class="family-tree-card"
    :style="style"
    @click="cardClick"
    @touchstart="onTouchStart"
    @touchmove.prevent="onTouchMove"
    @touchend="onTouchEnd"
    @mouseup.right="contextmenu"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
    @contextmenu.prevent
  >
    <div class="family-tree-card-content" :class="classes">
      <div v-if="person.has_hidden_subtree" class="hidden-subtree-indicator">
        <tree-icon :size="18" title="Additional Sub-Tree Hidden"></tree-icon>
      </div>
      <div class="picture-container">
        <img :src="profilePicture" v-if="profilePicture" class="profile-picture no-select" />
        <person-icon-colored v-else :gender="person.gender" :colored="colorCodeGender"></person-icon-colored>
      </div>
      <div class="name clip no-select">{{ fullName }}</div>
    </div>
    <div class="has-hints-icon" v-if="hasHints"><img :src="hintsIconSrc" /></div>
  </div>
</template>

<script>
import {getFullNameByLangMixed} from '@common/utils/utils.names';
import TreeIcon from 'vue-material-design-icons/AccountMultiple';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import {HEIGHT, UNKNOWN_NAME, WIDTH} from '../constants';

export default {
  props: {
    person: Object,
    isHovered: Boolean,
    hasHints: {type: Boolean, default: false},
    doubleClick: {type: Boolean, default: true},
    colorCodeGender: {type: Boolean, default: true},
  },
  data() {
    return {
      clicks: 0,
      doubleClickDelay: 200,
      touchContextMenuTimeoutId: null,
      touchContextMenuStart: null,
    };
  },
  computed: {
    classes() {
      let classes = [];
      if (this.person.is_start_person) {
        classes.push('is-start-person');
      }
      if (this.person.is_ghost) {
        classes.push('is-ghost');
      }
      if (this.person.is_current_user) {
        classes.push('is-current-user');
      }
      if (this.isHovered) {
        classes.push('hover');
      }
      return classes;
    },
    fullName() {
      if (this.person.first_names || this.person.surnames) {
        return (
          getFullNameByLangMixed(
            this.person.first_names,
            this.person.surnames,
            this.$store.getters.familyTreeIsLangChineseState
          ) || this.unknownName
        );
      }
      return this.person.full_name || this.unknownName;
    },
    unknownName() {
      return this.person.is_current_user ? 'You' : UNKNOWN_NAME;
    },
    style() {
      return {
        width: `${WIDTH}px`,
        'max-width': `${WIDTH}px`,
        height: `${HEIGHT}px`,
        'max-height': `${HEIGHT}px`,
      };
    },
    profilePicture() {
      return this.person.profile_picture && this.person.profile_picture.attachment;
    },
    hintsIconSrc() {
      return this.$getAsset('/assets/mcr_logo_dude_transparent.png');
    },
  },
  methods: {
    cardClick() {
      this.clicks++;
      if (this.doubleClick) {
        return this.handleDoubleClick();
      }
      return this.handleSingleClick();
    },
    handleDoubleClick() {
      if (this.clicks === 1) {
        setTimeout(() => {
          if (this.clicks > 1) {
            this.$emit('dblclick-card', this.person);
          } else {
            this.handleSingleClick();
          }
          this.clicks = 0;
        }, this.doubleClickDelay);
      }
    },
    handleSingleClick() {
      return this.$emit('click-card', this.person);
    },
    mouseenter() {
      this.$emit('mouseenter');
    },
    mouseleave() {
      this.$emit('mouseleave');
    },
    contextmenu(event) {
      this.$emit('contextmenu', event);
    },
    onTouchStart() {
      this.touchContextMenuStart = new Date().getTime();
      this.touchContextMenuTimeoutId = setTimeout(() => {
        this.contextmenu();
      }, 500);
    },
    onTouchMove(event) {
      const now = new Date().getTime();
      if (this.touchContextMenuStart && now - this.touchContextMenuStart < 400) {
        clearTimeout(this.touchContextMenuTimeoutId);
      }
    },
    onTouchEnd(event) {
      clearTimeout(this.touchContextMenuTimeoutId);
    },
  },
  components: {PersonIconColored, TreeIcon},
};
</script>

<style scoped lang="scss">
@import '@common/style/mixins';

$family-tree-card-padding: 10px;

.family-tree-card {
  cursor: pointer;
  display: flex;
  .family-tree-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% - #{$family-tree-card-padding} - #{$family-tree-card-padding});
    align-items: center;
    justify-content: flex-start;
    border: 2px solid $divider-line-color;
    border-radius: 4px;
    background-color: $background-alternate-color;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
    transition: all 0.1s ease;

    .name {
      max-width: calc(100% - #{$family-tree-card-padding} - 5px);
      height: 24px;
      transition: max-width 0.3s ease;
      &.clip {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:hover,
    &.hover {
      &:not(.is-ghost) {
        border: 2px solid $power-red;
        .name {
          z-index: 10;
          position: relative;
          max-width: 40vw;
          background-color: $background-alternate-color;
          border-radius: 2px;
          padding: 3px 5px;
          margin: -3px 0;
          &.clip {
          }
        }
      }
    }

    .picture-container {
      height: 135px;
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .profile-picture {
        width: 136px;
        height: 136px;
        display: block;
        position: absolute;
        left: 2px;
        top: 2px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }

    &.is-start-person {
      /* border: 2px solid #64646C; */
    }
    &.is-ghost {
      border: 2px dotted $border-color;
      background-color: $background-color;
      box-shadow: none;
      justify-content: center;
      .picture-container {
        height: auto;
        .person-icon-colored {
          transition: all 0.1s ease;
          opacity: 0.2;
          &::v-deep .person-icon {
            opacity: 0.7;
            .shape {
              stroke: #000;
              fill-opacity: 0;
            }
          }
          &.m,
          &.f {
            background-color: transparent;
          }
        }
      }
      .name {
        opacity: 0.5;
      }
      &:hover,
      &.hover {
        border: 2px dotted $active-color;
        .name {
          opacity: 1;
          color: $active-color;
          background-color: transparent;
        }
        .person-icon-colored {
          opacity: 0.6;
          &::v-deep .person-icon {
            .shape {
              stroke: $link-color;
            }
          }
        }
      }
    }
    &.is-current-user {
      &:hover,
      &.hover {
        &::v-deep .person-icon {
          .symbol {
            fill: $link-color;
          }
        }
      }
    }
  }
}
.has-hints-icon {
  background: $power-red;
  position: absolute;
  top: -11px;
  right: -11px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    margin-left: 1px;
    height: 23px;
  }
}
.hidden-subtree-indicator {
  position: absolute;
  display: flex;
  top: -20px;
  left: 2px;
  opacity: 0.3;
  .material-design-icon__svg {
    &:hover {
      /* color: $link-color; */
    }
  }
}
</style>
