<template>
  <div class="person-quick-form">
    <div class="form-fields">
      <slot name="extra-fields"></slot>

      <div class="data-row names-row">
        <div class="field">
          <div>
            <div>
              <div class="input-label label">EN First Name</div>
              <input
                ref="firstNameEn"
                class="input full-width"
                maxlength="250"
                :value="primaryEnFirstName"
                type="text"
                placeholder="Type in English or Chinese"
                @input="changePrimaryEnFirstName"
              />
            </div>
            <div>
              <div class="input-label label">EN Last Name</div>
              <input
                maxlength="250"
                class="input full-width"
                :value="primaryEnLastName"
                type="text"
                placeholder="Type in English or Chinese"
                @input="changePrimaryEnLastName"
              />
            </div>
          </div>
          <div>
            <div>
              <div class="input-label label">CN First Name</div>
              <input
                class="input full-width"
                maxlength="250"
                :value="primaryCnFirstName"
                type="text"
                placeholder="Type in English or Chinese"
                @input="changePrimaryCnFirstName"
              />
            </div>
            <div>
              <div class="input-label label">CN Last Name</div>
              <surname-autocomplete-select
                :value="primaryCnLastName"
                :init-search-value="primaryEnLastName"
                class="chinese-surname-select"
                placeholder="Type in English or Chinese"
                @change="onCnLastNameChange"
              ></surname-autocomplete-select>
            </div>
          </div>
        </div>
        <div v-if="showAllNamesLink">
          <router-link :to="fullProfileRoute">See all {{ totalNamesCount }} names </router-link>
        </div>
      </div>

      <div class="radio-row data-row">
        <div class="label">Gender</div>
        <div class="field radio">
          <div v-for="(name, value) in genderOptions">
            <input :id="value" type="radio" name="gender-radio" :value="value" v-model="personFormData.gender" />
            <label :for="value">{{ name }}</label>
          </div>
        </div>
      </div>

      <div class="radio-row data-row">
        <div class="label">Status</div>
        <div class="field radio">
          <div>
            <input
              id="living-status"
              type="radio"
              name="status-radio"
              :value="false"
              v-model="personFormData.is_deceased"
            />
            <label for="living-status">Living</label>
          </div>
          <div>
            <input
              id="deceased-status"
              type="radio"
              name="status-radio"
              :value="true"
              v-model="personFormData.is_deceased"
            />
            <label for="deceased-status">Deceased</label>
          </div>
          <div>
            <input
              id="unknown-status"
              type="radio"
              name="status-radio"
              :value="null"
              v-model="personFormData.is_deceased"
            />
            <label for="unknown-status">Unknown</label>
          </div>
        </div>
      </div>

      <div class="data-row">
        <div class="label">Birth Details</div>
        <div class="field">
          <approximate-date-field
            :day="personFormData.birth_date.day"
            :month="personFormData.birth_date.month"
            :year="personFormData.birth_date.year"
            :approximation-years="personFormData.birth_date.approximation_years"
            :show-approximation="false"
            @change-day="changeApproximateDate('birth_date', {day: $event})"
            @change-month="changeApproximateDate('birth_date', {month: $event})"
            @change-year="changeApproximateDate('birth_date', {year: $event})"
            @change-approximation-years="changeApproximateDate('birth_date', {approximation_years: $event})"
          ></approximate-date-field>
        </div>

        <div class="label">Birth Place</div>
        <family-tree-location-multiselect-container
          class="field"
          placeholder=""
          multiselect-classes="bordered"
          :location="personFormData.birth_location"
          :family-tree-id="personFormData.family_tree_id"
          :show-id-warning="true"
          @clear="clearBirthLocation"
          @select="changeBirthLocation"
        ></family-tree-location-multiselect-container>
      </div>

      <div class="data-row death-date-field" v-show="personFormData.is_deceased">
        <div class="label">Death Date</div>
        <div class="field">
          <approximate-date-field
            :day="personFormData.death_date.day"
            :month="personFormData.death_date.month"
            :year="personFormData.death_date.year"
            :approximation-years="personFormData.death_date.approximation_years"
            :show-approximation="false"
            @change-day="changeApproximateDate('death_date', {day: $event})"
            @change-month="changeApproximateDate('death_date', {month: $event})"
            @change-year="changeApproximateDate('death_date', {year: $event})"
            @change-approximation-years="changeApproximateDate('death_date', {approximation_years: $event})"
          ></approximate-date-field>
        </div>
      </div>

      <div class="data-row" v-show="personFormData.is_deceased">
        <div class="label">Death Place</div>
        <family-tree-location-multiselect-container
          class="field"
          placeholder=""
          multiselect-classes="bordered"
          :location="personFormData.death_location"
          :family-tree-id="personFormData.family_tree_id"
          :show-id-warning="true"
          @clear="clearDeathLocation"
          @select="changeDeathLocation"
        ></family-tree-location-multiselect-container>
      </div>
    </div>
  </div>
</template>

<script>
import SurnameAutocompleteSelect from '@common/elements/inputs/SurnameAutocompleteSelect';
import {isChineseText} from '@common/utils/utils';

import FamilyTreeLocationMultiselectContainer from '@/components/common/FamilyTreeLocationMultiselectContainer';
import approximateDateField from '@/components/common/tree/approximateDateField';

export default {
  props: {
    formInitialData: Object,
    genderOptions: Object,
    formLoading: Boolean,
    autofocusOnMount: {type: Boolean, default: true},
  },
  watch: {
    'formInitialData.surnames': {
      handler: function (value, oldValue) {
        this.personFormData.surnames = value;
        [this.primaryEnLastName, this.primaryCnLastName, this.otherLastNames] = this.initSplitNames(value);
      },
    },
  },
  data() {
    const personFormData = {...this.initPersonFormData()};
    const [primaryEnFirstName, primaryCnFirstName, otherFirstNames] = this.initSplitNames(personFormData.first_names);
    const [primaryEnLastName, primaryCnLastName, otherLastNames] = this.initSplitNames(personFormData.surnames);

    return {
      personFormData: personFormData,
      primaryEnFirstName: primaryEnFirstName,
      primaryCnFirstName: primaryCnFirstName,
      primaryEnLastName: primaryEnLastName,
      primaryCnLastName: primaryCnLastName,
      otherFirstNames,
      otherLastNames,
    };
  },
  mounted() {
    if (this.autofocusOnMount) {
      this.autofocus();
    }
  },
  computed: {
    showAllNamesLink() {
      return this.personFormData.object_id && (this.otherFirstNames.length || this.otherLastNames.length);
    },
    totalNamesCount() {
      const firstNames = this.personFormData.first_names || [];
      const surnames = this.personFormData.surnames || [];
      return firstNames.length + surnames.length;
    },
    fullProfileRoute() {
      const params = {id: this.personFormData.family_tree_id, personId: this.personFormData.object_id};
      const query = {tab: 'details', edit: 'true'};
      return {name: 'familytree-profile-details', params, query};
    },
  },
  methods: {
    initSplitNames(value) {
      if (!value) {
        return ['', '', []];
      }
      let [en, cn] = ['', ''];
      let other = [];

      for (let name of value) {
        if (name && name.value && !en && !isChineseText(name.value)) {
          en = name.value;
        } else if (name && name.value && !cn && isChineseText(name.value)) {
          cn = name.value;
        } else {
          other.push(name);
        }
      }
      return [en, cn, other];
    },
    initPersonFormData() {
      let data = {
        object_id: null,
        family_tree_id: null,
        birth_date: {},
        birth_location: null,
        death_date: {},
        death_location: null,
        first_names: [],
        surnames: [],
        gender: null,
        hierarchy_level: null,
        is_deceased: null,
        generation_number: null,
      };
      Object.keys(data).forEach(key => {
        const initialValue = this.formInitialData[key];
        data[key] = initialValue || initialValue === 0 ? initialValue : data[key];
      });
      data.is_deceased = [true, false, null].includes(this.formInitialData.is_deceased)
        ? this.formInitialData.is_deceased
        : null;
      data.id = data.object_id;
      return data;
    },
    getPersonForSave() {
      let person = {...this.personFormData};
      person.first_names = this.getFirstNamesForSave();
      person.surnames = this.getLastNamesForSave();
      if (!person.is_deceased) {
        person.death_location = null;
        person.death_date = null;
      }
      person.gender = person.gender === 'null' ? null : person.gender;
      this.approximateDateValuesToInt(person.birth_date);
      this.approximateDateValuesToInt(person.death_date);
      return person;
    },
    getFirstNamesForSave() {
      let names = this.primaryEnFirstName ? [{value: this.primaryEnFirstName, type: ''}] : [];
      if (this.primaryCnFirstName) {
        names.push({value: this.primaryCnFirstName, type: ''});
      }
      return [...names, ...this.otherFirstNames];
    },
    getLastNamesForSave() {
      let names = this.primaryEnLastName ? [{value: this.primaryEnLastName, type: ''}] : [];
      if (this.primaryCnLastName) {
        names.push({value: this.primaryCnLastName, type: ''});
      }
      return [...names, ...this.otherLastNames];
    },
    approximateDateValuesToInt(date) {
      if (date) {
        ['day', 'month', 'year', 'approximation_years'].forEach(key => {
          date[key] = parseInt(date[key]) || null;
        });
      }
    },
    changePrimaryEnFirstName(event) {
      this.primaryEnFirstName = event.target.value;
    },
    changePrimaryCnFirstName(event) {
      this.primaryCnFirstName = event.target.value;
    },
    changePrimaryEnLastName(event) {
      this.primaryEnLastName = event.target.value;
    },
    onCnLastNameChange(value) {
      this.primaryCnLastName = value;
    },
    changeApproximateDate(field_name, data) {
      this.personFormData[field_name] = {...this.personFormData[field_name], ...data};
    },
    autofocus() {
      this.$refs.firstNameEn.focus();
    },
    clearBirthLocation() {
      this.changeBirthLocation(null);
    },
    changeBirthLocation(location) {
      this.personFormData.birth_location = location;
    },
    clearDeathLocation() {
      this.changeDeathLocation(null);
    },
    changeDeathLocation(location) {
      this.personFormData.death_location = location;
    },
  },
  components: {approximateDateField, FamilyTreeLocationMultiselectContainer, SurnameAutocompleteSelect},
};
</script>

<style lang="scss" scoped>
.person-quick-form {
  &::v-deep .data-row {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    .field {
      flex-grow: 1;
      input.full-width {
        width: 100%;
      }
    }
  }

  .data-row.names-row {
    padding-bottom: 16px;
    border-bottom: 1px solid $neutral-300;
  }
  .data-row.names-row .field {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    row-gap: 24px;
    > * {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      > * {
        flex: 1;
      }
    }
  }
  .more-names {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .data-row.radio-row {
    .field.radio {
      display: flex;
      > div {
        margin-right: 15px;
        white-space: nowrap;
      }
    }
  }
  .field + .label {
    margin-top: 20px;
  }
  .label {
    color: $neutral-600;
    font-weight: 500;
    margin-right: 20px;
    flex-shrink: 0;
    margin-bottom: 8px;
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    padding-bottom: 150px;
    &::v-deep .data-row {
      flex-direction: column;
      align-items: stretch;
    }
    .label {
      margin-bottom: 5px;
      display: inline-block;
    }

    .data-row.names-row .field {
      row-gap: 8px;
      > * {
        flex-direction: column;
        row-gap: 8px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-mobile-se) {
    .radio-row {
      .field.radio {
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 8px;
      }
    }
  }
}
</style>
