<template>
  <div class="person-details-block item-block">
    <div class="header">
      <div class="heading-6">{{ title }}</div>
      <mcr-button
        v-if="familyTreePersonState.is_write_allowed"
        class="white small"
        @click="startEditMode"
        v-tooltip="editButtonTooltip"
        ><edit-icon :size="20" /><span>Edit</span></mcr-button
      >
    </div>
    <div class="text-md text-lg-mobile sections">
      <div v-for="section in visibleSections" :key="section.key" class="section" :class="section.class">
        <div class="content-item" v-for="sectionItem in section.items">
          <div v-if="sectionItem.sectionCategory" class="section-category text-sm text-md-mobile">
            {{ sectionItem.sectionCategory }}
          </div>
          <div class="label">{{ sectionItem.label }}</div>
          <div class="values">
            <div class="value" v-for="value in sectionItem.values">
              <div v-if="sectionItem.isHtml" v-html="value"></div>
              <residence-display
                v-else-if="sectionItem.isResidence"
                :date="value.date"
                :location="value.location"
                :note="value.note"
              >
              </residence-display>
              <date-location-display
                v-else-if="sectionItem.isDateLocation"
                :date="value.date"
                :location="value.location"
              ></date-location-display>
              <fact-display v-else-if="sectionItem.isFact" :fact="value"></fact-display>
              <div v-else>{{ value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {isChineseText} from '@common/utils/utils';
import sortBy from 'lodash/sortBy';
import EditIcon from 'vue-material-design-icons/Pencil';
import {mapGetters} from 'vuex';

import FactDisplay from '@/components/common/tree/FactDisplay';
import dateLocationDisplay from '@/components/common/tree/dateLocationDisplay';
import ResidenceDisplay from '@/components/common/tree/residenceDisplay.vue';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    showEditButtonTooltip: Boolean,
  },
  computed: {
    ...mapGetters(['familyTreePersonState']),
    sections() {
      const emptyValue = this.unknownLabel;

      const [enNames, cnNames] = this.splitNamesByLanguageAndType(this.familyTreePersonState.first_names, 'First Name');
      const [enSurnames, cnSurnames] = this.splitNamesByLanguageAndType(this.familyTreePersonState.surnames, 'Surname');

      let englishNamesItems = [
        ...Object.keys(enNames).map(key => {
          return {label: `English ${key}`, values: enNames[key]};
        }),
        ...Object.keys(enSurnames).map(key => {
          return {label: `English ${key}`, values: enSurnames[key]};
        }),
      ];
      let chineseNamesItems = [
        ...Object.keys(cnNames).map(key => {
          return {label: `Chinese ${key}`, values: cnNames[key]};
        }),
        ...Object.keys(cnSurnames).map(key => {
          return {label: `Chinese ${key}`, values: cnSurnames[key]};
        }),
      ];

      if (!englishNamesItems.length && !chineseNamesItems.length) {
        englishNamesItems = [
          {label: 'English First Name', values: [emptyValue]},
          {label: 'English Surname', values: [emptyValue]},
        ];
      }

      const commonInfoItems = [
        {label: 'Gender', values: [this.familyTreePersonState.gender_display]},
        {label: 'Status', values: [this.livingStatus]},
        {label: 'Occupation', values: [this.familyTreePersonState.occupation || emptyValue]},
        {label: 'Biography', values: [this.familyTreePersonState.notes_display || emptyValue], isHtml: true},
        {label: 'Generation Number', values: [this.familyTreePersonState.generation_number || emptyValue]},
      ];
      const birthItems =
        this.familyTreePersonState.birth_date || this.familyTreePersonState.birth_location
          ? [
              {
                label: 'Birth',
                values: [
                  {date: this.familyTreePersonState.birth_date, location: this.familyTreePersonState.birth_location},
                ],
                isDateLocation: true,
              },
            ]
          : [{label: 'Birth', values: [emptyValue]}];
      const deathItems =
        this.familyTreePersonState.death_date || this.familyTreePersonState.death_location
          ? [
              {
                label: 'Death',
                values: [
                  {date: this.familyTreePersonState.death_date, location: this.familyTreePersonState.death_location},
                ],
                isDateLocation: true,
              },
            ]
          : [{label: 'Death', values: [emptyValue]}];
      const residenceItems =
        this.familyTreePersonState.residence_location && this.familyTreePersonState.residence_location.length
          ? [
              {
                label: 'Residence',
                values: this.familyTreePersonState.residence_location.map(residence => {
                  return {
                    location: {place_id: residence.place_id, display_text: this.getFormattedResidenceText(residence)},
                    note: residence.note,
                  };
                }),
                isResidence: true,
              },
            ]
          : [{label: 'Residence', values: [emptyValue]}];

      const factsGrouped = {};
      this.familyTreePersonState.facts.forEach(fact => {
        const key = fact.fact_type.id;
        factsGrouped[key] = factsGrouped[key] || [];
        factsGrouped[key].push(fact);
      });
      const factSections = sortBy(Object.keys(factsGrouped), key => factsGrouped[key][0].fact_type.priority).map(
        key => {
          const label = factsGrouped[key][0].fact_type.label;
          const sectionCategory = factsGrouped[key][0].fact_type.category;
          return {
            key: `fact_${key}`,
            items: [{label: label, sectionCategory: sectionCategory, values: factsGrouped[key], isFact: true}],
            class: 'fact-section',
          };
        }
      );
      const nameItems = [
        {key: 'english_names', items: englishNamesItems},
        {key: 'chinese_names', items: chineseNamesItems},
      ].filter(item => item.items.length);
      return [
        ...nameItems,
        {key: 'birth', items: birthItems},
        {key: 'death', items: deathItems},
        {key: 'residence', items: residenceItems},
        {key: 'common_info', items: commonInfoItems},
        ...factSections,
      ];
    },
    visibleSections() {
      return this.sections;
    },
    title() {
      const names = this.familyTreePersonState.first_names;
      const name = names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
      return `${name}'s Details`;
    },
    livingStatus() {
      const mapping = {
        [true]: 'Deceased',
        [false]: 'Living',
      };
      return mapping[this.familyTreePersonState.is_deceased] || this.unknownLabel;
    },
    unknownLabel() {
      return 'Unknown';
    },
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.mainMenu;
    },
    editButtonTooltip() {
      const placement = this.isDesktop ? 'top' : 'bottom';
      return {
        content: 'You can edit all person\ndetails from here.',
        popperClass: 'text-center lower-layer',
        placement: placement,
        triggers: [],
        shown: this.showEditButtonTooltip,
      };
    },
  },
  methods: {
    splitNamesByLanguageAndType(names, defaultType) {
      let englishByType = {};
      let chineseByType = {};
      for (let name of names) {
        const typeDisplay = name.type_display || defaultType;
        if (isChineseText(name.value)) {
          chineseByType[typeDisplay] = chineseByType[typeDisplay] || [];
          chineseByType[typeDisplay].push(name.value);
        } else {
          englishByType[typeDisplay] = englishByType[typeDisplay] || [];
          englishByType[typeDisplay].push(name.value);
        }
      }
      return [englishByType, chineseByType];
    },
    getFormattedResidenceText(residence) {
      const years =
        residence.from_year || residence.to_year ? ` (${residence.from_year || '?'}-${residence.to_year || '?'})` : '';
      return `${residence.display_text || ''}${years}`;
    },
    startEditMode() {
      this.$emit('start-edit-mode');
    },
  },
  components: {ResidenceDisplay, McrButton, EditIcon, dateLocationDisplay, FactDisplay},
  name: 'PersonDetailsBlock',
};
</script>

<style lang="scss" scoped>
.person-details-block {
  .content-item::v-deep {
    .date-location-display {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .place-wrapper {
        display: flex;
        align-items: flex-start;
      }
      .material-design-icon {
        display: flex;
        width: 20px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .content-item {
      flex-direction: column;
      .label {
        width: max-content;
        margin-bottom: 4px;
      }
      .values {
        row-gap: 0;
      }
    }
  }
}
</style>
