<template>
  <div class="person-quick-create">
    <close-button @click="$emit('close')"></close-button>
    <div class="heading">
      Adding {{ relationType }} of <b>{{ fullName }}</b>
    </div>

    <div v-if="possibleChildrenList.length" class="extra-select">
      <div class="children-block">
        <div class="label">Include the following individuals as children:</div>
        <multiple-choice-select
          :options="possibleChildrenList"
          :checked="selectedPossibleChildrenIds"
          @change="childrenIdsChange"
        ></multiple-choice-select>
      </div>
    </div>

    <div v-if="possibleParentsList.length" class="extra-select">
      <div class="parents-block">
        <div class="label">Parents</div>
        <radio-select
          v-if="possibleParentsList.length"
          :options="possibleParentsList"
          :selected="selectedPossibleParentsIds"
          name="parents-radio"
          @change="parentsIdsChange"
        ></radio-select>
      </div>
    </div>

    <person-quick-form
      ref="form"
      :form-initial-data="formInitialData"
      :gender-options="familyTreePersonOptionsState.genders"
      @keyup.enter.native="save"
    ></person-quick-form>

    <div class="action-buttons">
      <a class="more-options-link" @click="openFullForm">More options</a>
      <mcr-button class="more-options-button dark-grey" :disabled="formLoading" @click="openFullForm"
        >More options</mcr-button
      >
      <mcr-button class="save-button" :loading="formLoading" @click="save">Add Relative</mcr-button>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import McrButton from '@common/elements/buttons/mcrButton';
import RadioSelect from '@common/elements/filters/radioSelect';
import {mapGetters} from 'vuex';

import MultipleChoiceSelect from '@/base/elements/filters/multipleChoiceSelect';

import PersonQuickForm from '@/components/modules/familyTree/tree/modals/quick/PersonQuickForm';

export default {
  props: {
    relationType: String,
    fullName: String,
    initialData: Object,
    possibleChildrenList: Array,
    possibleParentsList: Array,
    childrenIdsChange: Function,
    parentsIdsChange: Function,
    saveClickHandler: Function,
    fullFormOpener: Function,
  },
  watch: {
    'quickSidebarPropsState.prefillSurnames': function (value, oldValue) {
      this.formInitialData.surnames = value;
    },
  },
  data() {
    return {
      formInitialData: this.initialData ? {...this.initialData} : {},
    };
  },
  computed: {
    ...mapGetters([
      'familyTreePersonOptionsState',
      'familyTreePersonCreateLoadingState',
      'parenthoodCreateLoadingState',
      'coupleCreateLoadingState',
      'familyTreeDrawLoadingState',
      'quickSidebarPropsState',
    ]),
    formLoading() {
      return (
        this.familyTreePersonCreateLoadingState ||
        this.parenthoodCreateLoadingState ||
        this.coupleCreateLoadingState ||
        this.familyTreeDrawLoadingState
      );
    },
    selectedPossibleChildrenIds() {
      const selected = this.possibleChildrenList.filter(option => option.selected);
      return selected.length ? selected.map(option => option.id) : [];
    },
    selectedPossibleParentsIds() {
      const selected = this.possibleParentsList.find(option => option.selected);
      return selected ? selected.id : null;
    },
  },
  methods: {
    save() {
      const personData = this.$refs.form.getPersonForSave();
      this.saveClickHandler(personData);
    },
    openFullForm() {
      const personData = this.$refs.form.getPersonForSave();
      this.$emit('close');
      this.fullFormOpener(personData);
    },
  },
  components: {McrButton, PersonQuickForm, CloseButton, MultipleChoiceSelect, RadioSelect},
};
</script>

<style lang="scss" scoped>
.person-quick-create {
  padding: 26px 24px 100px;
  position: relative;

  .heading {
    font-size: 21px;
    margin-bottom: 24px;
    padding-bottom: 15px;
    border-bottom: 1px solid $divider-line-color;
  }
  .extra-select {
    margin-bottom: 30px;
  }

  .children-block,
  .parents-block {
    .label {
      color: $neutral-500;
      margin-bottom: 10px;
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    background: $off-white;

    .save-button {
      margin-left: 25px;
      flex-grow: 1;
    }
    .more-options-button {
      display: none;
    }
  }

  @media only screen and (max-width: $quick-sidebar-breakpoint) {
    padding: 26px 16px 166px;
    .action-buttons {
      border: none;
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 1;
      .mcr-button {
        border-radius: 0;
      }
      .save-button {
        margin-left: 0;
      }
      .more-options-link {
        display: none;
      }
      .more-options-button {
        display: flex;
      }
    }
  }

  @media only screen and (min-width: $quick-sidebar-breakpoint) {
    width: $quick-sidebar-width;

    .action-buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 16px 24px;
      width: $quick-sidebar-width;
      border-top: 1px solid $neutral-200;
      box-shadow: $slight-shadow;
      z-index: 2;
    }
  }
}
</style>
