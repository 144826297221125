<template>
  <delete-confirm-modal-content
    :loading="loading"
    :deleteFunction="deleteFunction"
    class="person-delete-confirm"
    @confirm="okClick"
    @close="cancelClick"
  >
    <h5>
      Are you sure you want to delete <span>{{ personName }}</span
      >?
    </h5>
    <p>Deleting will permanently erase their information.</p>
  </delete-confirm-modal-content>
</template>

<script>
import {mapGetters} from 'vuex';

import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

export default {
  components: {DeleteConfirmModalContent},
  props: {
    personName: String,
    deleteFunction: Function,
  },
  computed: {
    ...mapGetters(['familyTreePersonDeleteLoadingState', 'familyTreeDrawLoadingState']),
    loading() {
      return this.familyTreePersonDeleteLoadingState || this.familyTreeDrawLoadingState;
    },
  },
  methods: {
    cancelClick() {
      this.$emit('close');
    },
    okClick() {
      this.deleteFunction();
    },
  },
  name: 'personDeleteConfirmModalContent',
};
</script>

<style lang="scss" scoped>
h5 span {
  color: $active-color;
}
</style>
