<template>
  <ul class="record-match-list">
    <li v-if="placeName">
      <router-link :to="zupusSearchRoute" target="_blank">
        <span>View {{ zupusCount }} {{ surname }} records found in {{ placeName }}</span>
        <img :src="$getAsset('/assets/mcr-db-badge-logo.png')" />
      </router-link>
    </li>
    <li v-else>
      <router-link :to="zupusSearchRoute" target="_blank">
        <span>View {{ zupusCount }} {{ surname }} records in our database</span>
        <img :src="$getAsset('/assets/mcr-db-badge-logo.png')" />
      </router-link>
    </li>
  </ul>
</template>

<script>
import {TAB_ID_SOURCES} from '@common/utils/consts.search';

export default {
  props: {
    surname: String,
    zupusCount: Number,
    placeName: String,
    placeId: Number,
    clanId: String,
  },
  computed: {
    zupusSearchRoute() {
      return {
        name: 'search-tool-zupu',
        query: {clan_name: this.surname, ancestral_place_id: this.placeId, tab: TAB_ID_SOURCES},
      };
    },
  },
  name: 'ClanProfileZupusMatch',
};
</script>

<style lang="scss" scoped>
.record-match-list {
  margin: 0;
  color: $link-color;
  padding-left: 24px;
  li {
    padding: 8px 0;
    a {
      display: flex;
      align-items: flex-start;
      span {
        font-weight: bold;
        margin-right: 8px;
      }
      img {
        height: 20px;
      }
    }
  }
}
</style>
