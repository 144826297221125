<template>
  <div class="family-tree-search">
    <close-button @click="$emit('close')"></close-button>
    <div class="heading">Find Person in Tree</div>
    <div class="form">
      <search-icon class="search-icon"></search-icon>
      <input
        :value="query"
        placeholder="Search for persons in tree"
        class="search-input input"
        ref="search-input"
        @input="search"
      />
      <bounce-loading v-if="familyTreePersonsSearchListLoadingState" class="dark"></bounce-loading>
      <clear-icon class="clear-icon" v-else-if="query" @click="clearInput"></clear-icon>
    </div>

    <div v-if="displayQuery" class="results">
      <div v-if="!familyTreePersonsSearchListState.length && displayQuery">
        No persons matching <b>{{ displayQuery }}</b> in tree
      </div>

      <div v-for="item in familyTreePersonsSearchListState" class="result-item" @click="openProfile(item.object_id)">
        <div class="wrapper">
          <img
            :src="getProfilePicture(item.object_id)"
            v-if="getProfilePicture(item.object_id)"
            class="profile-picture"
          />
          <person-icon-colored v-else :gender="item.gender" :colored="colorCodeGender"></person-icon-colored>

          <div>
            <div class="text-md bold name">{{ item.full_name }}</div>
            <div class="text-sm living-status">{{ getLivingStatus(item) }}</div>
          </div>
        </div>
        <div
          class="tree-icon"
          @click.stop="jumpToPerson(item.object_id)"
          v-tooltip="{content: 'View in tree', popperClass: 'tooltip-info'}"
        >
          <tree-icon :size="20"></tree-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import {getLifetimeYears} from '@common/utils/utils';
import {getFamilyTreeRoute} from '@common/utils/utils.routes';
import ClearIcon from 'vue-material-design-icons/Close';
import TreeIcon from 'vue-material-design-icons/FileTree';
import SearchIcon from 'vue-material-design-icons/Magnify';
import {mapGetters} from 'vuex';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

export default {
  mounted() {
    this.$refs['search-input'].focus();
    this.$refs['search-input'].select();
  },
  data() {
    const query = this.$store.getters.familyTreePersonsSearchListMetaState.query || '';
    return {
      query: query,
      displayQuery: query,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreePersonsSearchListLoadingState',
      'familyTreePersonsSearchListState',
      'familyTreeDetailsIdState',
      'familyTreePersonsByIdState',
      'quickSidebarPropsState',
      'windowWidthState',
    ]),
    colorCodeGender() {
      return this.$store.getters.familyTreePreferencesState.color_code_gender;
    },
  },
  watch: {
    'quickSidebarPropsState.time': function (value, oldValue) {
      this.$refs['search-input'].focus();
      this.$refs['search-input'].select();
    },
  },
  methods: {
    search(event) {
      this.changeQuery(event);
      if (!this.query) {
        this.clearState();
      }

      this.$store
        .dispatch('searchPersonsInFamilyTreeAction', {familyTreeId: this.familyTreeDetailsIdState, q: this.query})
        .then(res => {
          this.displayQuery = this.query;
          if (
            this.familyTreePersonsSearchListState.length === 1 &&
            this.windowWidthState >= this.$breakpoints.mainMenu
          ) {
            this.jumpToPerson(this.familyTreePersonsSearchListState[0].object_id, false);
          }
        });
    },
    changeQuery(event) {
      this.query = event.target.value;
    },
    getLivingStatus(item) {
      return getLifetimeYears(item.birth_date, item.death_date, item.is_deceased);
    },
    getProfilePicture(personId) {
      const person = this.familyTreePersonsByIdState[personId];
      return person && person.profile_picture && person.profile_picture.attachment;
    },
    jumpToPerson(personId, allowReload = true) {
      const onFtRoute = this.$route.name === 'familytree-details';
      if (onFtRoute) {
        this.$store.commit('setFamilyTreeFocusPersonIdState', personId);
        if (this.windowWidthState < this.$breakpoints.mainMenu) {
          this.$emit('close');
        }
        return;
      }
      if (allowReload) {
        const query = {start_person_id: personId};
        const route = getFamilyTreeRoute(this.$store, query);
        this.$router.push(route);
      }
    },
    openProfile(personId) {
      const params = {id: this.familyTreeDetailsIdState, personId: personId};
      const query = {tab: 'details'};
      this.$router.push({name: 'familytree-profile-details', params, query});
    },
    clearInput() {
      this.query = '';
      this.displayQuery = '';
      this.clearState();
      this.$refs['search-input'].focus();
    },
    clearState() {
      this.$store.commit('setFamilyTreePersonsSearchListState', []);
      this.$store.commit('setFamilyTreePersonsSearchListMetaState', {});
    },
  },
  components: {BounceLoading, CloseButton, PersonIconColored, TreeIcon, ClearIcon, SearchIcon},
  name: 'FamilyTreeSearch',
};
</script>

<style lang="scss" scoped>
.family-tree-search {
  padding: 50px 30px;

  .heading {
    font-size: 21px;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid $divider-line-color;
  }

  .form {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    input {
      flex-grow: 1;
      padding-left: 40px;
      padding-right: 50px;
    }
    .bounce-loading {
      position: absolute;
      right: 15px;
      top: 11px;
    }
    .clear-icon {
      position: absolute;
      right: 12px;
      top: 10px;
      color: $neutral-500;
      cursor: pointer;
    }
    .search-icon {
      position: absolute;
      top: 10px;
      left: 12px;
      color: $neutral-500;
    }
  }

  .results {
    margin-top: 20px;

    .result-item {
      position: relative;
      .tree-icon {
        position: absolute;
        top: 22px;
        right: 22px;
        color: $neutral-500;
        padding: 8px;
        display: flex;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
          background: $neutral-200;
        }
      }
    }

    .result-item .wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 20px 0;
      padding: 12px 16px;
      border: 1px solid $neutral-200;
      background: white;
      border-radius: 4px;

      .living-status {
        color: $neutral-400;
      }

      .person-icon-colored,
      .profile-picture {
        width: 56px;
        height: 56px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 12px;

        &::v-deep svg {
          width: 56px;
          height: 56px;
        }
      }

      .name {
        max-width: 80%;
      }

      &:hover {
        box-shadow: 0 4px 8px -2px rgba(41, 39, 38, 0.05);
        .name {
          color: $primary-400;
        }
      }
    }
  }

  @media only screen and (min-width: $quick-sidebar-breakpoint) {
    width: $quick-sidebar-width;
  }
  @media only screen and (max-width: $breakpoint-mobile-se) {
    .form {
      display: flex;
      flex-direction: column;

      input {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
