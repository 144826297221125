<template>
  <div class="family-tree-mini-map-switch">
    <div class="item" @click="switchMap" v-tooltip="mapSwitchTooltip">
      <map-icon :size="24" title="" />
    </div>
  </div>
</template>

<script>
import MapIcon from 'vue-material-design-icons/Map';

export default {
  props: {
    isAvailable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    mapSwitchTooltip() {
      if (this.isAvailable) {
        return {content: 'Show mini map', placement: 'left', delay: {show: 1000}};
      }
      return {
        content: 'Mini map is not available for this tree',
        placement: 'left',
        delay: {show: 1000},
      };
    },
  },
  methods: {
    switchMap() {
      if (this.isAvailable) {
        this.$emit('switchMap');
      }
    },
  },
  components: {MapIcon},
  name: 'familyTreeMiniMapSwitch',
};
</script>

<style lang="scss" scoped>
.family-tree-mini-map-switch {
  position: fixed;
  right: 70px;
  bottom: 20px;
  border-radius: 4px;
  border: 1px solid $border-color-light;
  box-shadow: $box-shadow-extra-light;
  background: $background-alternate-color;
  color: $text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 20;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  &.is-active {
    .item {
      color: $mcr-red;
    }
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    right: 62px;
    bottom: 12px;
  }
}
</style>
