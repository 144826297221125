<template>
  <div class="familytree-onboarding-page loading" v-if="treeOnboardingDataLoadingState">
    <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
  </div>
  <div class="familytree-onboarding-page" v-else>
    <transition name="fade" mode="out-in">
      <div class="steps" v-if="showSteps" key="steps">
        <stepper-item
          name="Your details"
          number="1"
          :done="isMotherActive || isFatherActive || isGrandparentActive"
          :active="isMeActive"
        ></stepper-item>
        <div class="dash"></div>
        <stepper-item
          name="Add mother"
          number="2"
          :done="isFatherActive || isGrandparentActive"
          :active="isMotherActive"
        ></stepper-item>
        <div class="dash"></div>
        <stepper-item name="Add father" number="3" :done="isGrandparentActive" :active="isFatherActive"></stepper-item>
        <div class="dash"></div>
        <stepper-item name="Add grandparent" number="4" :done="false" :active="isGrandparentActive"></stepper-item>
      </div>
      <div v-else class="steps-margin" key="steps-margin"></div>
    </transition>

    <div class="content">
      <transition name="slide-left" mode="out-in">
        <router-view ref="step" @form-change="setFormHasData" @submit="onNextClick"></router-view>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="actions" v-if="showSteps">
          <div class="skip">
            <a @click="onSkipStepClick">I’ll do this later</a
            ><bounce-loading v-if="skipLoading" class="dark"></bounce-loading>
          </div>
          <div>
            <mcr-button class="next-button" :loading="buttonLoading" :disabled="disableNext" @click="onNextClick"
              >Next</mcr-button
            >
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import consts from '@/base/utils/consts';

import StepperItem from '@/components/modules/familyTreeOnboarding/StepperItem';

export default {
  beforeRouteEnter(to, from, next) {
    if (to.name === 'familytree-onboarding') {
      next({name: 'familytree-onboarding-start'});
    }
    next();
  },
  created() {
    this.$store.dispatch('fetchOnboardingDataAction').catch(() => {
      this.$toasted.error('Something went wrong. Please reload the page.');
    });
  },
  data() {
    return {
      buttonLoading: false,
      skipLoading: false,
      formHasData: false,
    };
  },
  computed: {
    ...mapGetters([
      'userFamilyTreeIdState',
      'treeOnboardingDataState',
      'treeOnboardingDataLoadingState',
      'treeOnboardingUpdateLoadingState',
      'userEmailState',
    ]),
    stepRoutes() {
      return consts.ONBOARDING_STEPS;
    },
    currentStepIndex() {
      return this.stepRoutes.indexOf(this.$route.name);
    },
    showSteps() {
      return this.$route.meta.isStep;
    },
    treeRoute() {
      return {name: 'familytree-details', params: {id: this.userFamilyTreeIdState}};
    },
    nextStepName() {
      return this.stepRoutes[this.currentStepIndex + 1];
    },
    nextStepRoute() {
      return this.nextStepName ? {name: this.nextStepName} : this.treeRoute;
    },
    isMeActive() {
      return this.currentStepIndex === 1;
    },
    isMotherActive() {
      return this.currentStepIndex === 2;
    },
    isFatherActive() {
      return this.currentStepIndex === 3;
    },
    isGrandparentActive() {
      return this.currentStepIndex === 4;
    },
    disableNext() {
      return this.skipLoading || !this.formHasData;
    },
    stepTrackNameByRouteName() {
      return {
        'familytree-onboarding-me': 'Birth info',
        'familytree-onboarding-mother': 'Mother',
        'familytree-onboarding-father': 'Father',
        'familytree-onboarding-grandparent': 'Grandparent',
      };
    },
  },
  methods: {
    setFormHasData(hasData) {
      this.formHasData = hasData;
    },
    saveCurrentStepCompleted() {
      const onboarded = !this.nextStepName;
      if (onboarded) {
        const {mother, father, grandparent} = this.treeOnboardingDataState;
        const hasRelatives = Boolean(mother || father || grandparent);
        this.$store.commit('setTreeOnboardingSuccessMessageState', {hasRelatives});
      }
      const payload = {isOnboarded: onboarded, onboardingStep: this.nextStepName};
      return this.$store.dispatch('updateOnboardingDataAction', payload);
    },
    onSkipStepClick() {
      if (this.treeOnboardingUpdateLoadingState) {
        return;
      }
      this.skipLoading = true;
      this.trackSkipStepClick(this.$route.name);
      this.saveCurrentStepCompleted()
        .then(() => {
          this.$router.push(this.nextStepRoute);
        })
        .catch(() => {
          this.$toasted.error('Error while completing the step. Please try again later.');
        })
        .finally(() => {
          this.skipLoading = false;
        });
    },
    onNextClick() {
      if (this.disableNext) {
        return;
      }
      this.buttonLoading = true;
      const routeName = this.$route.name;
      const data = this.$refs.step.getData();
      this.trackNextStepClick(routeName, data);
      const mapping = {
        'familytree-onboarding-me': this.acceptDataMe,
        'familytree-onboarding-mother': this.acceptDataMother,
        'familytree-onboarding-father': this.acceptDataFather,
        'familytree-onboarding-grandparent': this.acceptDataGrandparent,
      };
      const method = mapping[routeName];
      if (!method) {
        return this.saveCurrentStepCompleted()
          .then(() => {
            this.$router.push(this.nextStepRoute);
          })
          .catch(() => {
            this.$toasted.error('Error while completing the step. Please try again later.');
          })
          .finally(() => {
            this.buttonLoading = false;
          });
      }

      const dataSavePromise = method(data);
      const completeStepPromise = this.saveCurrentStepCompleted();

      Promise.all([dataSavePromise, completeStepPromise])
        .then(([dataRes, stepRes]) => {
          this.clearTree();
          this.$router.push(this.nextStepRoute);
        })
        .catch(() => {
          this.$toasted.error('Failed to save data to person. Please try again later.');
        })
        .finally(() => {
          this.buttonLoading = false;
        });
    },
    acceptDataMe(data) {
      this.$store.commit('mutateTreeOnboardingDataState', {fieldName: 'me', data});
      const id = this.treeOnboardingDataState.me.object_id;
      return this.$store.dispatch('familyTreePersonAcceptDataAction', {id, data});
    },
    acceptDataMother(data) {
      const {me, mother, father, family_tree_id} = this.treeOnboardingDataState;
      const payload = {...data, gender: 'f', family_tree_id: family_tree_id, hierarchy_level: me.hierarchy_level - 1};
      this.$store.commit('mutateTreeOnboardingDataState', {fieldName: 'mother', data: payload});
      return this.acceptDataParent(payload, mother, father, me, 'mother');
    },
    acceptDataFather(data) {
      const {me, mother, father, family_tree_id} = this.treeOnboardingDataState;
      const payload = {...data, gender: 'm', family_tree_id: family_tree_id, hierarchy_level: me.hierarchy_level - 1};
      this.$store.commit('mutateTreeOnboardingDataState', {fieldName: 'father', data: payload});
      return this.acceptDataParent(payload, father, mother, me, 'father');
    },
    acceptDataParent(data, mainParent, otherParent, child, fieldName) {
      const {family_tree_id} = this.treeOnboardingDataState;

      if (mainParent && mainParent.object_id) {
        return this.$store.dispatch('familyTreePersonAcceptDataAction', {id: mainParent.object_id, data});
      }
      const otherId = otherParent ? otherParent.object_id : null;
      const payload = {...data, family_tree_id: family_tree_id, hierarchy_level: child.hierarchy_level - 1};
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('createFamilyTreePersonAction', payload)
          .then(created => {
            const onboardingData = {fieldName: fieldName, data: {object_id: created.object_id}};
            this.$store.commit('mutateTreeOnboardingDataState', onboardingData);
            this.createParenthood(child.object_id, created.object_id, otherId)
              .then(() => {
                resolve({person: created});
              })
              .catch(reject);
          })
          .catch(reject);
      });
    },
    acceptDataGrandparent(data) {
      const fieldName = 'grandparent';
      const {mother, father, grandparent, family_tree_id} = this.treeOnboardingDataState;
      let child = data.parentGender === 'm' ? father : mother;
      let payload = {...data, family_tree_id: family_tree_id};

      if (child && child.object_id) {
        payload.hierarchy_level = child.hierarchy_level - 1;
        this.$store.commit('mutateTreeOnboardingDataState', {fieldName: fieldName, data: payload});
        return this.acceptDataParent(payload, grandparent, null, child, fieldName);
      }

      return new Promise((resolve, reject) => {
        const childPromise = data.parentGender === 'm' ? this.acceptDataFather : this.acceptDataMother;
        childPromise({}).then(data => {
          child = data.person;
          payload.hierarchy_level = child.hierarchy_level - 1;
          this.$store.commit('mutateTreeOnboardingDataState', {fieldName: fieldName, data: payload});
          this.acceptDataParent(payload, grandparent, null, child, fieldName).then(resolve).catch(reject);
        });
      });
    },
    createParenthood(childId, motherId, fatherId) {
      return this.$store.dispatch('createParenthoodAction', {
        parent_ids: [motherId, fatherId].filter(id => !!id),
        child_ids: [childId],
      });
    },
    clearTree() {
      this.$store.dispatch('cleanupTreeStateAction');
    },
    trackNextStepClick(routeName, data) {
      const count = data
        ? Object.values(data).filter(value => {
            if (value && typeof value === 'object' && value.hasOwnProperty('year')) {
              return value.year || value.month || value.day;
            }
            return value && !isEmpty(value);
          }).length
        : 0;
      const stepName = this.stepTrackNameByRouteName[routeName];
      AnalyticsAmplitudeHandler.trackOnboardingNextStepEvent(stepName, count);
    },
    trackSkipStepClick(routeName) {
      const stepName = this.stepTrackNameByRouteName[routeName];
      AnalyticsAmplitudeHandler.trackOnboardingSkipStepEvent(stepName);
    },
  },
  components: {StepperItem, McrButton, BounceLoading},
  name: 'FamilyTreeOnboarding',
};
</script>

<style lang="scss" scoped>
.familytree-onboarding-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $background-light;
  padding: 72px 16px 40px;
  overflow: hidden;

  .content {
    width: 100%;
    max-width: 560px;

    &::v-deep .place-suggestions {
      display: flex;
      flex-wrap: wrap;
      column-gap: 12px;
      row-gap: 12px;
      margin-top: 12px;

      .chip {
        cursor: pointer;

        .plus-icon {
          color: $neutral-500;
          margin: 0 0 0 5px;
          font-size: 20px;
        }
      }
    }
  }
  .steps-margin {
    height: 64px;
  }
  .steps {
    display: flex;
    column-gap: 16px;
    max-width: 560px;
    width: 100%;
    margin-bottom: 40px;
    align-items: center;
    > * {
      flex: 1;
    }

    .dash {
      height: 1px;
      background: $neutral-200;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    .skip {
      display: flex;
      align-items: center;
      .bounce-loading {
        margin-left: 8px;
      }
    }

    .next-button {
      min-width: 120px;
    }
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    padding-top: 64px;
    padding-bottom: 100px;

    .steps-margin {
      height: 56px;
    }
    .steps {
      margin-bottom: 32px;
    }

    .actions {
      border-top: 1px solid $neutral-200;
      position: fixed;
      background: $background-light;
      z-index: 2;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 16px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-leave-active {
  transition-duration: 0s;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
