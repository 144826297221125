<template>
  <div class="person-profile-picture-crop modal-content">
    <h5>
      Drag and zoom<br />
      to crop this picture.
    </h5>
    <actions-helper></actions-helper>
    <div class="wrapper">
      <vue-croppa
        v-model="croppa"
        v-if="!loading"
        :height="256"
        :width="256"
        :zoom-speed="7"
        :prevent-white-space="true"
        :show-remove-button="false"
        :disable-drag-and-drop="true"
        :disable-click-to-choose="true"
        placeholder="Image loading failed"
        @new-image-drawn="zoom"
      >
        <img crossOrigin="anonymous" :src="initialImageSrc" slot="initial" />
      </vue-croppa>
    </div>
    <mcr-loading-indicator v-if="loading" :loading="loading"></mcr-loading-indicator>
    <mcr-button :loading="saveLoading" @click="okClick" class="full-width">Save</mcr-button>
    <close-button @click="cancelClick"></close-button>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import McrButton from '@common/elements/buttons/mcrButton';
import VueCroppa from 'vue-croppa';

import ActionsHelper from '@/components/modules/familyTree/actionsHelper';

export default {
  props: {
    initialImageSrc: String,
    loading: Boolean,
    saveLoading: Boolean,
    onCrop: {type: Function, required: false},
  },
  data() {
    return {
      croppa: {},
    };
  },
  methods: {
    zoom() {
      this.croppa.zoom(true, 20);
    },
    okClick() {
      this.croppa.generateBlob(
        blob => {
          this.$emit('cropped', blob);
          if (this.onCrop) {
            this.onCrop(blob);
          }
        },
        'image/jpeg',
        0.5
      );
    },
    cancelClick() {
      this.$emit('close');
    },
  },
  components: {McrButton, ActionsHelper, CloseButton, VueCroppa: VueCroppa.component},
  name: 'personProfilePictureCrop',
};
</script>

<style lang="scss" scoped>
.person-profile-picture-crop {
  position: relative;

  h5 {
    width: 258px;
  }

  .wrapper {
    text-align: center;
  }

  &::v-deep .croppa-container {
    align-self: center;
    margin: 20px 0;
    border: 1px solid $divider-line-color;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: $box-shadow;
    background-color: $photo-border-color;
  }

  .family-tree-actions-helper {
    position: relative;
    left: 0;
    top: 0;
    opacity: 0.8;
    margin-top: 15px;
    &::v-deep .quick-actions {
      display: none;
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    min-height: auto;
    border-radius: 4px;
  }
}
</style>
