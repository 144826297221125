<template>
  <transition name="fade" v-if="treeUsageTutorialState" mode="out-in">
    <div class="overlay-tutorial-animated no-select" v-if="treeUsageTutorialState.showDrag" key="drag">
      <mouse-drag-animation></mouse-drag-animation>
      <div class="label text-md">Drag to pan</div>
    </div>
    <div class="overlay-tutorial-animated no-select" v-else-if="treeUsageTutorialState.showZoom" key="zoom">
      <mouse-scroll-animation></mouse-scroll-animation>
      <div class="label text-md">Scroll to zoom</div>
    </div>
  </transition>
</template>

<script>
import MouseDragAnimation from '@common/elements/animations/MouseDragAnimation';
import MouseScrollAnimation from '@common/elements/animations/MouseScrollAnimation';
import {mapGetters} from 'vuex';

export default {
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['treeUsageTutorialState']),
  },
  components: {MouseDragAnimation, MouseScrollAnimation},
  name: 'OverlayTutorialAnimated',
};
</script>

<style lang="scss" scoped>
.overlay-tutorial-animated {
  position: absolute;
  z-index: 20;
  width: 168px;
  height: 168px;
  background: rgba(0, 0, 0, 0.58);
  border-radius: 99px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .animation {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
  }

  .mouse-drag-animation {
    top: 36px;
    width: 80px;
  }

  .mouse-scroll-animation {
    top: 36px;
    width: 50px;
  }

  .label {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    color: white;
    font-weight: 500;
    bottom: 31px;
  }
}
</style>
