<template>
  <div class="family-tree-card-menu" :style="style">
    <slot name="content">
      <div v-if="showFullProfile" class="flex-row" @click.stop="fullProfileClick">
        <account-icon :size="iconsSize" title="Full Profile"></account-icon>
        <span class="label">Full Profile</span>
      </div>

      <div v-if="editable" class="flex-row" @click.stop="editClick">
        <pencil-icon :size="iconsSize" title="Quick Edit"></pencil-icon>
        <span class="label">Quick Edit</span>
      </div>

      <div v-if="editableFull" class="flex-row" @click.stop="fullProfileEditClick">
        <account-edit-icon :size="iconsSize" title="Full Edit"></account-edit-icon>
        <span class="label">Full Edit</span>
      </div>

      <div v-if="showAddRelative" class="flex-row" @click.stop="addRelativeClick">
        <plus-icon :size="iconsSize" title="Add Relative"></plus-icon>
        <span class="label">Add Relative</span>
      </div>

      <div v-if="removable" class="flex-row" @click.stop="deletePersonClick">
        <delete-icon :size="iconsSize" title="Delete"></delete-icon>
        <span class="label">Delete</span>
      </div>
      <div v-if="showViewTree" class="flex-row" @click.stop="viewTreeClick">
        <eye-icon :size="iconsSize" title="View Tree"></eye-icon>
        <span class="label">View Tree</span>
      </div>
      <div v-if="showViewLineage" class="flex-row" @click.stop="viewLineageClick">
        <lineage-icon :size="iconsSize" title="View Lineage"></lineage-icon>
        <span class="label">View Lineage</span>
      </div>
    </slot>
  </div>
</template>

<script>
import AccountIcon from 'vue-material-design-icons/Account';
import AccountEditIcon from 'vue-material-design-icons/AccountEdit';
import DeleteIcon from 'vue-material-design-icons/Delete';
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare';
import PencilIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import EyeIcon from 'vue-material-design-icons/Sitemap';

import {CONTEXT_MENU_SECTIONS} from '@/components/modules/familyTree/constants';

const SECTIONS = Object.values(CONTEXT_MENU_SECTIONS);

const SECTION_SIZE = 41;

export default {
  components: {PencilIcon, DeleteIcon, PlusIcon, EyeIcon, LineageIcon, AccountIcon, AccountEditIcon},
  props: {
    sections: {
      type: Array,
      validator: value => value.every(item => SECTIONS.includes(item)),
      default: () => SECTIONS,
    },
    scale: {type: Number, default: 1},
    cardHeight: {type: Number},
  },
  computed: {
    style() {
      const transformedScaleReverse = 1 / this.scale;
      const currentWidth = 140;
      const scaledWidth = currentWidth * transformedScaleReverse;
      const fixedMargin = 10 * (transformedScaleReverse * 2);
      const marginDifference = (currentWidth - scaledWidth) / 2 + currentWidth * transformedScaleReverse + fixedMargin;
      const selfHeight = this.sections.length * SECTION_SIZE;
      const difference = selfHeight - this.cardHeight;
      return {
        'font-size': `18px`,
        top: `${(this.cardHeight - this.cardHeight - difference) / 2}px`,
        transform: `scale(${transformedScaleReverse})`,
        left: `${marginDifference}px`,
      };
    },
    iconsSize() {
      return 22;
    },
    showFullProfile() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE);
    },
    removable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.DELETE);
    },
    editable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.EDIT);
    },
    editableFull() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE_EDIT);
    },
    showAddRelative() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
    },
    showViewTree() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_TREE);
    },
    showViewLineage() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_LINEAGE);
    },
  },
  methods: {
    fullProfileClick(event) {
      this.$emit('click-full-profile', event);
    },
    editClick(event) {
      this.$emit('click-edit', event);
    },
    fullProfileEditClick(event) {
      this.$emit('click-full-profile-edit', event);
    },
    deletePersonClick(event) {
      this.$emit('click-delete', event);
    },
    viewTreeClick(event) {
      this.$emit('click-view-tree', event);
    },
    viewLineageClick(event) {
      this.$emit('click-view-lineage', event);
    },
    addRelativeClick(event) {
      this.$emit('click-add-relative', event);
    },
  },
  name: 'familyTreeCardMenu',
};
</script>

<style lang="scss" scoped>
.family-tree-card-menu {
  .loading-message {
    color: $off-white;
    padding: 8px 12px;
    opacity: 0.8;
  }
  position: absolute;
  z-index: 20;
  min-width: 160px;
  background-color: $power-red;
  border-radius: 4px;
  transition: all 100ms linear;

  .flex-row {
    display: flex;
    align-items: center;
  }

  .flex-row,
  &::v-deep .option {
    // .option comes from `person-details-add-relative-popover` in content slot
    padding: 8px 12px;
    position: relative;
    z-index: 22;
    color: $off-white;
    white-space: nowrap;
    cursor: pointer;

    .label {
      position: relative;
      top: 1px;
      margin-left: 12px;
    }

    &:hover {
      background-color: $active-color;
    }
    &:first-child {
      padding-top: 9px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      padding-bottom: 9px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &:after {
    background-color: $power-red;
    /* box-shadow: -3px 3px 2px 0 rgba( 0, 0, 0, .05 ); */
    content: '\00a0';
    z-index: 21;
    display: block;
    height: 12px;
    width: 12px;
    left: -6px;
    top: calc(50% - 6px);
    position: absolute;
    transform: rotate(45deg);
    /* border-left: 2px solid $active-color;
    border-bottom: 2px solid $active-color;
    left: -9px; */
  }
}
</style>
