<template>
  <div
    class="person-quick-view"
    @dragenter.stop.prevent
    @dragover.stop.prevent="onDragover"
    @dragleave.self="onDragleave"
    @drop.stop.prevent="onDrop"
  >
    <close-button @click="$emit('close')"></close-button>
    <transition name="fade">
      <drag-drop-prompt v-show="isDragover"></drag-drop-prompt>
    </transition>
    <div class="heading">
      <div class="picture-container">
        <img
          :src="profilePictureAttachment"
          v-if="profilePictureAttachment"
          class="profile-picture"
          @click="profilePictureClick"
        />
        <div class="loading-profile-picture" v-else-if="showProfilePictureLoading"></div>
        <person-icon-colored v-else :gender="person.gender" @click.native="personIconClick"></person-icon-colored>
      </div>
      <div class="name-container">
        <h4 v-if="personPrimaryFullnameEn">{{ personPrimaryFullnameEn }}</h4>
        <h4 v-if="personPrimaryFullnameCn">{{ personPrimaryFullnameCn }}</h4>
        <h4 v-if="!personPrimaryFullnameEn && !personPrimaryFullnameCn">{{ unknownName }}</h4>
        <div class="strapline" v-if="livingStatus">{{ livingStatus }}</div>
      </div>
    </div>
    <person-quick-view-actions
      :person-name="personPrimaryFullnameEn || personPrimaryFullnameCn || 'this person'"
      :sections="contextMenuSections"
      :relatives-loading="$store.getters.familyTreePersonRelativesLoadingState"
      :can-add-parents="canAddParents"
      :can-add-sibling="canAddSibling"
      :hints-count="hintsCount"
      @click-full-profile="fullProfileHandler"
      @click-full-profile-edit="fullProfileEditHandler"
      @click-quick-edit="quickEditHandler"
      @click-view-tree="viewTreeHandler"
      @click-view-lineage="viewLineageHandler"
      @click-delete="deleteHandler"
      @click-add-parent="addParentHandler"
      @click-add-spouse="addSpouseHandler"
      @click-add-sibling="addSiblingHandler"
      @click-add-child="addChildHandler"
      @click-show-hints="showHintsHandler"
    ></person-quick-view-actions>
    <div class="details">
      <div class="section">
        <person-photos-container
          ref="photos"
          :add-allowed="addPhotosAllowed"
          :person-id="person.id"
          :person-name-en="personPrimaryFullnameEn"
          :person-name-cn="personPrimaryFullnameCn"
          :explicit-loading="showExplicitPhotosLoading"
          @files-selected="filesSelected"
          @file-uploaded="fileUploaded"
        >
          <div class="label no-margin" slot="title">Photos</div>
        </person-photos-container>
      </div>

      <div class="flex-row section" v-if="person.notes_display">
        <div>
          <div class="label">Biography</div>
          <div v-html="person.notes_display"></div>
        </div>
      </div>
      <div class="flex-row" v-for="(names, typeDisplay) in firstNamesByType">
        <div class="label">{{ typeDisplay }}</div>
        <div class="value flex-column name">
          <div v-for="(name, index) in names" :key="index">{{ name }}</div>
        </div>
      </div>

      <div class="flex-row" v-for="(names, typeDisplay) in surnamesByType">
        <div class="label">{{ typeDisplay }}</div>
        <div class="value flex-column name">
          <div v-for="(name, index) in names" :key="index">
            <a v-if="getChineseSurname(name)" @click="showClanModal(name)">{{ name }}</a>
            <span v-else>{{ name }}</span>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="label">Birth</div>
        <date-location-display
          class="value value-place"
          v-if="person.birth_date || person.birth_location"
          :date="person.birth_date"
          :location="person.birth_location"
        ></date-location-display>
        <div class="value" v-else>Unknown</div>
      </div>

      <div class="flex-row" v-if="person.death_date || person.death_location">
        <div class="label">Death</div>
        <date-location-display
          class="value value-place"
          v-if="person.death_date || person.death_location"
          :date="person.death_date"
          :location="person.death_location"
        ></date-location-display>
        <div class="value" v-else>Unknown</div>
      </div>

      <div class="flex-row" v-if="person.occupation">
        <div class="label">Occupation</div>
        <div class="value">{{ person.occupation }}</div>
      </div>

      <div class="flex-row" v-if="person.generation_number">
        <div class="label">Generation #</div>
        <div class="value">{{ person.generation_number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import DragDropPrompt from '@common/elements/layouts/DragDropPrompt';
import {getLifetimeYears} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import {mapGetters} from 'vuex';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import DateLocationDisplay from '@/components/common/tree/dateLocationDisplay';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import personProfilePictureCropModalContentContainer from '@/components/modules/familyTree/tree/gallery/personProfilePictureCropModalContentContainer';
import PersonPhotosContainer from '@/components/modules/familyTree/tree/modals/personPhotosContainer';

import PersonQuickViewActions from './PersonQuickViewActions';

export default {
  props: {
    person: Object,
    addPhotosAllowed: Boolean,
    contextMenuSections: Array,
    hintsCount: Number,
    fullProfileHandler: Function,
    fullProfileEditHandler: Function,
    quickEditHandler: Function,
    viewTreeHandler: Function,
    viewLineageHandler: Function,
    deleteHandler: Function,
    addParentHandler: Function,
    addSpouseHandler: Function,
    addSiblingHandler: Function,
    addChildHandler: Function,
    showHintsHandler: Function,
  },
  data() {
    return {
      addPhotosInitiator: 'personQuickView',
      profilePictureProcessing: false,
      cropperInitialImage: {},
      isDragover: false,
    };
  },
  computed: {
    ...mapGetters(['familyTreePersonsByIdState', 'familyTreePersonProfilePictureLoadingState']),
    livingStatus() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    profilePicture() {
      const person = this.familyTreePersonsByIdState[this.person.id];
      return person && person.profile_picture && person.profile_picture;
    },
    profilePictureAttachment() {
      return this.profilePicture && this.profilePicture.attachment;
    },
    firstNamesByType() {
      let byType = {};
      for (let firstName of this.person.first_names || []) {
        const typeDisplay = firstName.type_display || 'First Name';
        byType[typeDisplay] = byType[typeDisplay] || [];
        byType[typeDisplay].push(firstName.value);
      }
      return byType;
    },
    surnamesByType() {
      let byType = {};
      for (let surname of this.person.surnames || []) {
        const typeDisplay = surname.type_display || 'Surname';
        byType[typeDisplay] = byType[typeDisplay] || [];
        byType[typeDisplay].push(surname.value);
      }
      return byType;
    },
    personPrimaryFullnameEn() {
      return getFullNameEn(this.person.first_names || [], this.person.surnames || []);
    },
    personPrimaryFullnameCn() {
      return getFullNameCn(this.person.first_names || [], this.person.surnames || []);
    },
    unknownName() {
      return this.person.is_current_user ? 'You' : UNKNOWN_NAME;
    },
    personParents() {
      return this.$store.getters.getFamilyTreePersonRelativesState(this.person.object_id).parents || [];
    },
    canAddParents() {
      return this.personParents.length !== 2;
    },
    canAddSibling() {
      return Boolean(this.personParents.length);
    },
    showProfilePictureLoading() {
      return this.familyTreePersonProfilePictureLoadingState || this.profilePictureProcessing;
    },
    showExplicitPhotosLoading() {
      return Boolean(this.profilePictureAttachment);
    },
  },
  methods: {
    personIconClick() {
      this.$refs.photos.addPhotos(this.addPhotosInitiator);
    },
    getChineseSurname(surname) {
      if (this.$store.getters.familyTreeSurnamesState.includes(surname)) {
        return surname;
      }
      const meta = this.$store.getters.familyTreeSurnamesMetaState;
      return meta && meta.to_traditional && meta.to_traditional[surname];
    },
    showClanModal(surname) {
      const clanName = this.getChineseSurname(surname);
      this.$modal.hide('person-details');
      this.$modal.show(`clan-profile-modal-${clanName}`);
    },
    async profilePictureClick() {
      let photo = this.$refs.photos.galleryOverlayItems.find(item => item.object_id === this.profilePicture.asset_id);
      if (photo) {
        return this.$refs.photos.showGallery(photo);
      }
      /* if profile picture is not in photos section, get and cache the full attachment url */
      let fullAttachment = this.profilePicture.fullAttachment;
      if (!fullAttachment) {
        const asset = await this.$store.dispatch('getAssetDetailsAction', {assetId: this.profilePicture.asset_id});
        fullAttachment = asset.attachment;
        const profilePictureData = {...this.profilePicture, fullAttachment: asset.attachment};
        this.$store.commit('mutateFamilyTreePersonsByIdState', {
          id: this.person.id,
          profile_picture: profilePictureData,
        });
      }
      const profilePicture = {
        object_id: this.profilePicture.asset_id,
        src: this.profilePicture.fullAttachment,
        _id: this.profilePicture.asset_id,
        ...this.$refs.photos.photoMetadata,
      };
      this.$store.commit('setGalleryItemsState', [profilePicture]);
      this.$store.dispatch('displayGalleryAction', profilePicture._id);
    },
    filesSelected({initiator}) {
      if (
        initiator === this.addPhotosInitiator ||
        (!this.$refs.photos.photos.length && !this.profilePictureAttachment)
      ) {
        this.profilePictureProcessing = true;
      }
    },
    fileUploaded(asset) {
      if (!this.cropperInitialImage.attachment && this.profilePictureProcessing) {
        this.cropperInitialImage = asset;
        const props = {
          initialImageSrc: this.cropperInitialImage.attachment,
          personId: this.person.object_id,
          assetId: this.cropperInitialImage.object_id,
        };
        const modalParams = {classes: 'clear_modal', name: personProfilePictureCropModalContentContainer.modalName};
        this.$modal.show(personProfilePictureCropModalContentContainer, props, modalParams);
        this.profilePictureProcessing = false;
      }
    },
    onDragover(e) {
      this.isDragover = true;
    },
    onDragleave(e) {
      this.isDragover = false;
    },
    onDrop(e) {
      this.isDragover = false;
      let fileList = e.dataTransfer.files;
      if (fileList.length > 0) {
        this.$refs.photos.filesSelected(fileList);
      }
    },
  },
  components: {
    DragDropPrompt,
    PersonPhotosContainer,
    CloseButton,
    PersonIconColored,
    DateLocationDisplay,
    PersonQuickViewActions,
  },
  name: 'PersonQuickView',
};
</script>

<style lang="scss" scoped>
.person-quick-view {
  padding: 30px;
  min-height: 100%;
  box-sizing: border-box;
  position: relative;

  .heading {
    display: flex;
    padding-bottom: 20px;

    .name-container {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      word-break: break-word;
      h4 {
        margin: 0;
      }
    }

    .strapline {
      color: $text-alternate-color;
      margin-top: 8px;
    }
  }

  .person-photos-container {
    padding-top: 15px;
    padding-bottom: 15px;
    &::v-deep .add-button {
      margin-top: 0;
      color: $mcr-grey;
      &:hover {
        color: $link-color;
      }
    }
  }

  &::v-deep .label {
    width: 140px;
    color: $mcr-grey;
    margin-right: 15px;
    flex-shrink: 0;
  }

  .flex-row {
    padding: 10px 0;

    .value-place {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      &::v-deep .place-wrapper {
        display: flex;
        align-items: flex-start;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .section {
    border-bottom: 1px solid $border-color-light;
    margin-bottom: 10px;
    .label:not(.no-margin) {
      margin-bottom: 10px;
    }
  }

  .picture-container {
    width: 136px;
    height: 136px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .loading-profile-picture {
    width: 100%;
    height: 100%;
    background-color: $photo-border-color;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: $quick-sidebar-breakpoint) {
    .heading {
      .name-container {
        max-width: 210px;
      }
    }
    width: $quick-sidebar-width;
  }
}
</style>
