<template>
  <div class="drag-prompt">
    <upload-icon :size="50" class="upload-icon"></upload-icon>
    <div>Drop media & files here to upload</div>
  </div>
</template>

<script>
import UploadIcon from 'vue-material-design-icons/FileUpload';

export default {
  components: {UploadIcon},
  name: 'DragDropPrompt',
};
</script>

<style lang="scss" scoped>
.drag-prompt {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  border: 1px dashed $divider-line-light;
  border-radius: 4px;
  color: $mcr-light-grey;
  background-color: rgba($background-alternate-color, 0.94);
  padding-top: 150px;
  font-size: 20px;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;

  .upload-icon {
    margin-bottom: 20px;
  }
}
</style>
