<template>
  <div class="familytree-card-container">
    <div class="menu-container">
      <family-tree-card-menu
        v-if="(menuShowingAllowed && isDetailed) || showMenu"
        v-show="showMenu"
        :sections="visibleContextMenuSections"
        :scale="scale"
        :card-height="cardHeight"
        @click-full-profile="goToPersonProfilePage(person)"
        @click-edit="onClickEdit(person)"
        @click-full-profile-edit="onClickFullProfileEdit(person)"
        @click-view-tree="clickViewTree(person)"
        @click-view-lineage="clickViewLineage(person)"
        @click-delete="handleDeleteClick(person)"
        @click-add-relative="handleAddRelativeClick"
        @mouseenter.native="mouseenter"
        @mouseleave.native="mouseleave"
      >
        <div slot="content" v-if="showAddRelativeSubmenu">
          <div class="loading-message" v-if="familyTreePersonRelativesLoadingState">Fetching relatives...</div>
          <person-details-add-relative-popover
            v-else
            :can-add-sibling="personHasParents"
            :can-add-parents="!personHasBothParents"
            @click-add-parent="addParentContextMenuHandler(person)"
            @click-add-spouse="addSpouseContextMenuHandler(person)"
            @click-add-sibling="addSiblingContextMenuHandler(person)"
            @click-add-child="addChildContextMenuHandler(person)"
          ></person-details-add-relative-popover>
        </div>
      </family-tree-card-menu>

      <div class="card-tooltip-wrapper" :style="cardTooltipWrapperStyle" v-if="treeCardTooltipText">
        <div class="card-tooltip">{{ treeCardTooltipText }}</div>
        <div class="card-tooltip-arrow-down"></div>
      </div>

      <context-menu-popover
        v-if="!menuShowingAllowed"
        :show-trigger-element="false"
        :sections="visibleContextMenuSections"
        :can-add-parent="!personHasBothParents"
        :can-add-sibling="personHasParents"
        ref="mobile-context-menu"
        @click-full-profile="goToPersonProfilePage(person)"
        @click-edit="onClickEdit(person)"
        @click-full-profile-edit="onClickFullProfileEdit(person)"
        @click-add-parent="addParentContextMenuHandler(person)"
        @click-add-spouse="addSpouseContextMenuHandler(person)"
        @click-add-sibling="addSiblingContextMenuHandler(person)"
        @click-add-child="addChildContextMenuHandler(person)"
        @click-view-tree="clickViewTree(person)"
        @click-view-lineage="clickViewLineage(person)"
        @click-delete="handleDeleteClick(person)"
      ></context-menu-popover>

      <slot
        name="card"
        :immediateHideMenu="immediateHideMenu"
        :showMenu="showMenu"
        :handlePersonClick="onClick"
        :openContextMenu="openContextMenu"
        :mouseenter="mouseenter"
        :mouseleave="mouseleave"
      >
        <family-tree-card
          v-right-click-outside="immediateHideMenu"
          ref="card"
          :is-hovered="isHoveredState"
          :person="person"
          :has-hints="personHasHints"
          :color-code-gender="familyTreePreferencesState.color_code_gender"
          @click-card="onClick"
          @dblclick-card="openEditPersonQuickSidebar"
          @contextmenu="openContextMenu"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
        ></family-tree-card>
      </slot>
    </div>
  </div>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import ContextMenuPopover from '@/components/common/tree/ContextMenuPopover';

import {
  CONTEXT_MENU_SECTIONS,
  HEIGHT,
  QUICK_EDIT_HELPER_KEY,
  QUICK_VIEW_HELPER_KEY,
  RELATION_TYPE_NAMES,
  UNKNOWN_NAME,
} from '@/components/modules/familyTree/constants';
import FamilyTreeCard from '@/components/modules/familyTree/tree/familyTreeCard';
import FamilyTreeCardMenu from '@/components/modules/familyTree/tree/familyTreeCardMenu';
import personDeleteConfirmModalContent from '@/components/modules/familyTree/tree/modals/personDeleteConfirmModalContent';
import personDeleteFailedModalContent from '@/components/modules/familyTree/tree/modals/personDeleteFailedModalContent';
import PersonDetailsAddRelativePopover from '@/components/modules/familyTree/tree/modals/personDetailsAddRelativePopover';
import personDetailsModalContent from '@/components/modules/familyTree/tree/modals/personDetailsModalContent';
import PersonQuickCreate from '@/components/modules/familyTree/tree/modals/quick/PersonQuickCreate';
import PersonQuickEdit from '@/components/modules/familyTree/tree/modals/quick/PersonQuickEdit';
import PersonQuickView from '@/components/modules/familyTree/tree/modals/quick/PersonQuickView';
import {
  createRelativeHandler,
  getNewPersonGender,
  getNewPersonGenerationNumberByRelationType,
  getNewPersonHierarchyLevelByRelationType,
  getNewPersonSurnameByRelationType,
  getPossibleChildrenList,
  getPossibleParentsList,
} from '@/components/modules/familyTree/tree/services';
import {
  closeQuickSidebar,
  openAddPersonModal,
  openQuickSidebar,
} from '@/components/modules/familyTree/tree/services.modals';

import {PARENT_RELATION_TYPE, SIBLING_RELATION_TYPE, SPOUSE_RELATION_TYPE, CHILD_RELATION_TYPE} from '../constants';

const SECTIONS = Object.values(CONTEXT_MENU_SECTIONS);

export default {
  props: {
    person: Object,
    handleClickAllowed: Boolean,
    scale: {type: Number, default: 1},
    contextMenuSections: {
      type: Array,
      validator: value => value.every(item => SECTIONS.includes(item)),
      default: () => SECTIONS,
    },
    cardHeight: {type: Number, default: HEIGHT},
    quickActionsEnabled: {type: Boolean, default: true},
    isDetailed: {type: Boolean, default: true},
    viewTreeAction: {type: Function, required: false},
  },
  data() {
    return {
      showAddRelativeSubmenu: false,
      showMenu: false,
      cardHovering: false,
      showMenuTimeout: null,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreeDetailsIdState',
      'familyTreePersonRelativesLoadingState',
      'isFamilyTreeWriteAllowedState',
      'quickSidebarPropsState',
      'familyTreeFocusPersonIdState',
      'familyTreePreferencesState',
      'showCardQuickEditTooltipState',
      'showCardQuickViewTooltipState',
    ]),
    personParents() {
      return this.$store.getters.getFamilyTreePersonRelativesState(this.person.object_id).parents || [];
    },
    personParentsLength() {
      return this.personParents.length;
    },
    personHasParents() {
      return Boolean(this.personParentsLength);
    },
    personHasBothParents() {
      return this.personParentsLength === 2;
    },
    menuShowingAllowed() {
      return this.$store.getters.windowWidthState > this.$breakpoints.mobile;
    },
    visibleContextMenuSections() {
      let sections = [...this.contextMenuSections];
      const indexDelete = sections.indexOf(CONTEXT_MENU_SECTIONS.DELETE);
      if (
        indexDelete > -1 &&
        (!this.isFamilyTreeWriteAllowedState || this.person.is_current_user || this.isHomePerson)
      ) {
        sections.splice(indexDelete, 1);
      }
      const indexEdit = sections.indexOf(CONTEXT_MENU_SECTIONS.EDIT);
      if (indexEdit > -1 && !this.isFamilyTreeWriteAllowedState) {
        sections.splice(indexEdit, 1);
      }
      const indexEditFull = sections.indexOf(CONTEXT_MENU_SECTIONS.FULL_PROFILE_EDIT);
      if (indexEditFull > -1 && !this.isFamilyTreeWriteAllowedState) {
        sections.splice(indexEditFull, 1);
      }
      const indexAddRelative = sections.indexOf(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
      if (indexEdit > -1 && !this.isFamilyTreeWriteAllowedState) {
        sections.splice(indexAddRelative, 1);
      }
      return sections;
    },
    personHasHints() {
      return Boolean(this.$store.getters.familyTreeHintsCountsByPersonIdsState[this.person.id]);
    },
    isRouteLineage() {
      return this.$route.name === 'familytree-lineage';
    },
    isHoveredState() {
      if (this.showMenu) {
        return true;
      }
      if (this.quickSidebarPropsState.personId) {
        return this.quickSidebarPropsState.personId === this.person.id;
      }
      return this.familyTreeFocusPersonIdState == this.person.id;
    },
    isHomePerson() {
      return this.person.object_id === this.$store.getters.familyTreeHomePerson.id;
    },
    cardTooltipWrapperStyle() {
      const transformedScaleReverse = 1 / this.scale;
      const top = 30 * transformedScaleReverse * -1;
      const textLength = this.treeCardTooltipText ? this.treeCardTooltipText.length : 0;
      const left = textLength > 16 ? '0' : '20';
      return {
        top: `${top}px`,
        left: `${left}px`,
        transform: `scale(${transformedScaleReverse})`,
      };
    },
    treeCardTooltipText() {
      if (this.$route.name !== 'familytree-details' || !this.isHomePerson) {
        return null;
      }
      if (this.showCardQuickEditTooltipState && this.isEditAllowed) {
        return this.menuShowingAllowed ? 'Double-click to edit' : 'Double-tap to edit';
      }
      if (this.showCardQuickViewTooltipState) {
        return this.menuShowingAllowed ? 'Click to view' : 'Tap to view';
      }
    },
    isEditAllowed() {
      return this.visibleContextMenuSections.includes(CONTEXT_MENU_SECTIONS.EDIT);
    },
  },
  methods: {
    openContextMenu() {
      this.cardHovering = true;
      this.changeShowMenu(true);
    },
    mouseenter() {
      this.cardHovering = true;

      if (this.menuShowingAllowed) {
        this.showMenuTimeout = setTimeout(() => {
          this.changeShowMenu(true);
        }, 800);
      }
    },
    mouseleave() {
      this.cardHovering = false;

      if (this.menuShowingAllowed) {
        clearTimeout(this.showMenuTimeout);
        setTimeout(() => {
          if (!this.cardHovering) {
            this.changeShowMenu(false);
            this.showAddRelativeSubmenu = false;
          }
        }, 500);
      }
    },
    immediateHideMenu() {
      clearTimeout(this.showMenuTimeout);
      this.changeShowMenu(false);
      this.showAddRelativeSubmenu = false;
    },
    changeShowMenu(value) {
      if (!this.isDetailed) {
        return;
      }
      if (this.menuShowingAllowed) {
        this.showMenu = value;
      } else {
        this.$refs['mobile-context-menu'].isPopoverOpen = value;
      }
    },
    getDetailsModalName() {
      return `person-details`;
    },
    getEditModalName() {
      return `person-edit`;
    },
    getDeleteModalName() {
      return `person-delete`;
    },
    getDeleteFailedModalName() {
      return `person-delete-failed`;
    },
    getCreateModalName() {
      return 'person-create';
    },
    clickViewTree(person) {
      if (this.viewTreeAction) {
        return this.viewTreeAction(this.familyTreeDetailsIdState, person.object_id);
      }
      const query = {start_person_id: person.object_id};
      this.$router.push({name: 'familytree-details', params: {id: this.familyTreeDetailsIdState}, query});
    },
    clickViewLineage(person) {
      const query = {start_person_id: person.object_id};
      this.$router.push({name: 'familytree-lineage', params: {id: this.familyTreeDetailsIdState}, query});
    },
    reFetchLineage() {
      const params = {id: this.$route.params.id, start_person_id: this.$route.query.start_person_id};
      this.$store.dispatch('fetchFamilyTreeLineageAction', params);
    },
    detailsModalOpened(personId) {
      return () => {
        this.$store.commit('setGalleryCurrentIndexState', null);
        this.$store.dispatch('fetchFamilyTreePersonAction', personId);
        if (isEmpty(this.$store.getters.getFamilyTreePersonRelativesState(personId))) {
          this.$store.dispatch('fetchFamilyTreePersonRelativesAction', personId);
        }
        this.$store.dispatch('fetchFamilyTreePersonPhotosAction', personId);
        this.$store.dispatch('fetchFamilyTreePersonSavedMentionsAction', {person_id: personId});
      };
    },
    editModalOpened(personId) {
      return () => {
        let person = this.$store.getters.familyTreePersonState;
        if (!person || person.object_id.toString() !== personId.toString()) {
          this.$store.dispatch('fetchFamilyTreePersonAction', personId);
        }
        if (isEmpty(this.$store.getters.familyTreePersonOptionsState)) {
          this.$store.dispatch('fetchFamilyTreePersonOptionsAction');
        }
      };
    },
    editModalBeforeClose(person) {
      this.$store.commit('setFamilyTreePersonUpdateErrorsState', null);
      this.openFullViewPersonModal(person);
    },
    modalBeforeOpen() {
      this.$store.commit('setFamilyTreePersonState', null);
      closeQuickSidebar(this);
    },
    relativeClickHandler(relativeId) {
      this.$modal.hide(this.getDetailsModalName());
      setTimeout(() => {
        // since the modals have the same name, have to wait until open
        this.openFullViewPersonModal({id: relativeId, object_id: relativeId});
      }, 100);
    },
    handleAddRelativeClick() {
      if (isEmpty(this.$store.getters.getFamilyTreePersonRelativesState(this.person.object_id))) {
        this.$store.dispatch('fetchFamilyTreePersonRelativesAction', this.person.object_id);
      }
      this.showAddRelativeSubmenu = !this.showAddRelativeSubmenu;
    },
    addParentHandler(person) {
      return this.openAddPersonModal(null, PARENT_RELATION_TYPE, person);
    },
    addSpouseHandler(person) {
      return this.openAddPersonModal(null, SPOUSE_RELATION_TYPE, person);
    },
    addSiblingHandler(person) {
      return this.openAddPersonModal(null, SIBLING_RELATION_TYPE, person);
    },
    addChildHandler(person) {
      return this.openAddPersonModal(null, CHILD_RELATION_TYPE, person);
    },
    addParentContextMenuHandler(person) {
      if (this.quickActionsEnabled) {
        return this.openAddPersonQuickSidebar(PARENT_RELATION_TYPE, person);
      }
      return this.addParentHandler(person);
    },
    addSpouseContextMenuHandler(person) {
      if (this.quickActionsEnabled) {
        return this.openAddPersonQuickSidebar(SPOUSE_RELATION_TYPE, person);
      }
      return this.addSpouseHandler(person);
    },
    addSiblingContextMenuHandler(person) {
      if (this.quickActionsEnabled) {
        return this.openAddPersonQuickSidebar(SIBLING_RELATION_TYPE, person);
      }
      return this.addSiblingHandler(person);
    },
    addChildContextMenuHandler(person) {
      if (this.quickActionsEnabled) {
        return this.openAddPersonQuickSidebar(CHILD_RELATION_TYPE, person);
      }
      return this.addChildHandler(person);
    },
    onClickEdit(person) {
      if (this.quickActionsEnabled) {
        return this.openEditPersonQuickSidebar(person);
      }
      return this.goToPersonProfilePage(person, true);
    },
    onClickFullProfileEdit(person) {
      return this.goToPersonProfilePage(person, true);
    },
    onClick(person) {
      if (this.quickActionsEnabled) {
        return this.openViewPersonQuickSidebar(person);
      }
      return this.goToPersonProfilePage(person);
    },
    openFullViewPersonModal(person) {
      let events = {
        opened: this.detailsModalOpened(person.object_id),
        'before-open': this.modalBeforeOpen,
      };
      let contextMenuSections = [...this.visibleContextMenuSections];
      const indexFullProfile = contextMenuSections.indexOf(CONTEXT_MENU_SECTIONS.FULL_PROFILE);
      if (indexFullProfile > -1) {
        contextMenuSections.splice(indexFullProfile, 1);
      }
      let props = {
        contextMenuSections: contextMenuSections,
        relativeClickHandler: this.relativeClickHandler,
        editClickHandler: (person, initialData) => this.goToPersonProfilePage(person, true),
        deleteClickHandler: this.handleDeleteClick,
        viewTreeClickHandler: () => {
          this.clickViewTree(person);
        },
        viewLineageClickHandler: () => {
          this.clickViewLineage(person);
        },
        addParentHandler: this.addParentHandler,
        addSpouseHandler: this.addSpouseHandler,
        addSiblingHandler: this.addSiblingHandler,
        addChildHandler: this.addChildHandler,
      };
      let modalParams = {classes: 'clear_modal', scrollable: true, height: 'auto', name: this.getDetailsModalName()};
      this.$modal.show(personDetailsModalContent, props, modalParams, events);
    },
    goToPersonProfilePage(person, editMode = false) {
      const params = {id: this.familyTreeDetailsIdState, personId: person.object_id};
      const query = {tab: 'details'};
      if (editMode) {
        query['edit'] = 'true';
      }
      this.immediateHideMenu();
      this.$router.push({name: 'familytree-profile-details', params, query});
    },
    handleDeleteClick(person) {
      let props = {
        personName: person.full_name || 'this person',
        deleteFunction: () => {
          this.$store
            .dispatch('deleteFamilyTreePersonAction', person.object_id)
            .then(() => {
              closeQuickSidebar(this);
            })
            .catch(() => {
              let props = {error: this.$store.getters.familyTreePersonDeleteErrorsState};
              let modalParams = {classes: 'clear_modal', name: this.getDeleteFailedModalName()};
              this.$modal.show(personDeleteFailedModalContent, props, modalParams);
            })
            .finally(() => {
              this.$modal.hide(this.getDeleteModalName());
              this.$modal.hide(this.getDetailsModalName());
            });
        },
      };
      let modalParams = {classes: 'clear_modal', name: this.getDeleteModalName()};
      this.$modal.show(personDeleteConfirmModalContent, props, modalParams);
    },
    openAddPersonModal(initialData, relationType, person) {
      const treeId = this.$store.getters.familyTreeDetailsIdState;
      const modalName = this.getCreateModalName();
      const openDetails = true;
      const refetchTree = true;
      const callback = formData => {
        this.$modal.hide(modalName);
        this.$modal.hide(this.getDetailsModalName());
        if (this.isRouteLineage && relationType === PARENT_RELATION_TYPE && formData.gender === 'm') {
          this.reFetchLineage();
        }
      };
      openAddPersonModal(
        initialData,
        relationType,
        person,
        treeId,
        modalName,
        openDetails,
        refetchTree,
        callback,
        this
      );
    },
    openAddPersonQuickSidebar(relationType, person) {
      const possibleParentsList = getPossibleParentsList(relationType, person, this);
      const possibleChildrenList = getPossibleChildrenList(relationType, person, this);
      let childrenIds = possibleChildrenList.filter(option => option.selected).map(option => option.id);
      let parentsIds = possibleParentsList.find(option => option.selected);
      parentsIds = parentsIds ? parentsIds.id : [];
      let surnames = getNewPersonSurnameByRelationType(relationType, person, parentsIds, this);

      const sidebarProps = {
        id: `${relationType}${person.id}`,
        personId: person.id,
        prefillSurnames: surnames,
      };

      const props = {
        relationType: RELATION_TYPE_NAMES[relationType],
        fullName: person.full_name || UNKNOWN_NAME,
        initialData: {
          gender: getNewPersonGender(relationType, person, this),
          hierarchy_level: getNewPersonHierarchyLevelByRelationType(person.hierarchy_level, relationType),
          generation_number: getNewPersonGenerationNumberByRelationType(person.generation_number, relationType),
          family_tree_id: this.familyTreeDetailsIdState,
          surnames: surnames,
        },
        possibleChildrenList: possibleChildrenList,
        possibleParentsList: possibleParentsList,
        childrenIdsChange: value => {
          childrenIds = value;
        },
        parentsIdsChange: value => {
          parentsIds = value;
          const newSurnames = getNewPersonSurnameByRelationType(relationType, person, parentsIds, this);
          this.$store.commit('setQuickSidebarPropsState', {...sidebarProps, prefillSurnames: newSurnames});
        },
        saveClickHandler: async formData => {
          await createRelativeHandler(formData, relationType, person, null, childrenIds, parentsIds, false, true, this);
          closeQuickSidebar(this);
        },
        fullFormOpener: formData => {
          closeQuickSidebar(this);
          this.openAddPersonModal(formData, relationType, person);
        },
      };
      let events = {
        opened: () => {
          this.$emit('open-sidebar', person.id);
          this.$store.commit('setFamilyTreeFocusPersonIdState', null);
        },
      };
      openQuickSidebar(this, PersonQuickCreate, sidebarProps, props, events);
    },
    openEditPersonQuickSidebar(person) {
      if (!this.isEditAllowed) {
        return;
      }
      let events = {
        opened: () => {
          this.$emit('open-sidebar', person.id);
          this.$store.commit('addReviewedItemState', QUICK_EDIT_HELPER_KEY);
          this.$store.commit('setShowCardQuickEditTooltipState', false);
          this.$store.commit('setFamilyTreeFocusPersonIdState', null);
          AnalyticsAmplitudeHandler.trackTreeOpenQuickEditEvent(person.id);
        },
        'before-close': () => {
          if (!this.$store.getters.reviewedItemsState.includes(QUICK_VIEW_HELPER_KEY)) {
            this.$store.commit('setShowCardQuickViewTooltipState', true);
          }
        },
      };

      const sidebarProps = {
        id: `edit${person.id}`,
        personId: person.id,
      };
      const props = {
        fullName: person.full_name || UNKNOWN_NAME,
        initialData: person,
        saveClickHandler: async (formData, profilePictureData) => {
          const personPromise = this.$store.dispatch('updateFamilyTreePersonAction', formData);
          const picturePromise = this.updateProfilePicture(profilePictureData, person.id);

          Promise.all([personPromise, picturePromise]).then(responses => {
            this.$store.dispatch('fetchFamilyTreeSurnamesListAction', responses[0].family_tree_id);
            closeQuickSidebar(this);
            const action = [
              {
                text: 'View Profile',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                  this.goToPersonProfilePage(person);
                },
              },
            ];
            this.$toasted.success(`Changes saved! `, {action});
          });
        },
        fullFormOpener: formData => {
          AnalyticsAmplitudeHandler.trackTreeEditDetailsEvent(person.id);
          closeQuickSidebar(this);
          this.goToPersonProfilePage(person, true);
        },
      };
      openQuickSidebar(this, PersonQuickEdit, sidebarProps, props, events);
    },
    updateProfilePicture(profilePictureData, personId) {
      if (!profilePictureData.blob) {
        return Promise.resolve();
      }
      return new Promise((resolve, reject) => {
        if (profilePictureData.file) {
          return this.$store
            .dispatch('uploadFamilyTreePersonPhotoAction', {
              personId: personId,
              file: profilePictureData.file,
            })
            .then(res => {
              this.$store
                .dispatch('setFamilyTreePersonProfilePictureAction', {
                  personId: personId,
                  assetId: res.object_id,
                  file: profilePictureData.blob,
                })
                .then(() => {
                  resolve();
                });
            });
        }
        return this.$store
          .dispatch('setFamilyTreePersonProfilePictureAction', {
            personId: personId,
            assetId: profilePictureData.asset_id,
            file: profilePictureData.blob,
          })
          .then(() => {
            resolve();
          });
      });
    },
    openViewPersonQuickSidebar(person) {
      if (!this.handleClickAllowed) {
        return;
      }
      if (isEmpty(this.$store.getters.getFamilyTreePersonRelativesState(person.id))) {
        this.$store.dispatch('fetchFamilyTreePersonRelativesAction', person.id);
      }
      let events = {
        opened: () => {
          this.$store.commit('addReviewedItemState', QUICK_VIEW_HELPER_KEY);
          this.$store.commit('setShowCardQuickViewTooltipState', false);
          this.$store.dispatch('fetchFamilyTreePersonPhotosAction', person.id);
          this.$emit('open-sidebar', person.id);
          this.$store.commit('setFamilyTreeFocusPersonIdState', null);
        },
      };

      const sidebarProps = {
        id: `view${person.id}`,
        personId: person.id,
      };
      const hintsCount = this.$store.getters.familyTreeHintsCountsByPersonIdsState[person.id] || 0;
      const props = {
        person: person,
        hintsCount: hintsCount,
        addPhotosAllowed: this.isFamilyTreeWriteAllowedState,
        contextMenuSections: this.visibleContextMenuSections,
        fullProfileHandler: () => this.goToPersonProfilePage(person),
        fullProfileEditHandler: () => this.goToPersonProfilePage(person, true),
        quickEditHandler: () => this.openEditPersonQuickSidebar(person),
        viewTreeHandler: () => this.clickViewTree(person),
        viewLineageHandler: () => this.clickViewLineage(person),
        deleteHandler: () => this.handleDeleteClick(person),
        addParentHandler: () => this.openAddPersonQuickSidebar(PARENT_RELATION_TYPE, person),
        addSpouseHandler: () => this.openAddPersonQuickSidebar(SPOUSE_RELATION_TYPE, person),
        addSiblingHandler: () => this.openAddPersonQuickSidebar(SIBLING_RELATION_TYPE, person),
        addChildHandler: () => this.openAddPersonQuickSidebar(CHILD_RELATION_TYPE, person),
        showHintsHandler: () => {
          AnalyticsAmplitudeHandler.trackHintClickButtonEvent();
          const route = {
            name: 'familytree-profile-details',
            params: {id: this.familyTreeDetailsIdState, personId: person.id},
            query: {tab: 'hints'},
          };
          this.$router.push(route);
        },
      };

      openQuickSidebar(this, PersonQuickView, sidebarProps, props, events);
    },
  },
  components: {FamilyTreeCard, FamilyTreeCardMenu, PersonDetailsAddRelativePopover, ContextMenuPopover},
};
</script>

<style lang="scss" scoped>
.familytree-card-container {
  position: absolute;
}
.menu-container {
  position: relative;
}

.card-tooltip-wrapper {
  position: absolute;
  z-index: 20;
}
.card-tooltip {
  border-radius: 5px;
  background: $neutral-800;
  color: white;
  padding: 5px 10px;
  font-size: 13px;
  white-space: nowrap;
}
.card-tooltip-arrow-down {
  width: 0;
  height: 0;
  margin: -1px auto 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid $neutral-800;
}
</style>
