<template>
  <div class="clan-profile-member">
    <img v-if="profilePicture" :src="profilePicture" class="member-avatar" />
    <div v-else class="member-avatar no-avatar" :class="gender">
      <person-icon :gender="gender"></person-icon>
    </div>
    <div class="member-name">{{ fullName }}</div>
  </div>
</template>

<script>
import PersonIcon from '@/base/icons/person/PersonIcon';

export default {
  props: {
    fullName: String,
    profilePicture: String,
    gender: String,
  },
  components: {PersonIcon},
};
</script>

<style lang="scss" scoped>
.clan-profile-member {
  display: flex;
  align-items: center;
  margin-top: 4px;

  .member-avatar {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    background-color: $photo-border-color;
    border: 1px solid $photo-border-color;
    margin-right: 8px;
    flex-shrink: 0;
    &.no-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      &.m {
        background-color: $male-background-color;
        border-color: $male-border-color;
      }
      &.f {
        background-color: $female-background-color;
        border-color: $female-border-color;
      }
      .person-icon::v-deep {
        width: 72%;
        .shape,
        .symbol {
          fill-opacity: 1;
        }
      }
    }
  }

  .member-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
