<template>
  <div class="person-quick-view-actions" :style="actionsStyle">
    <div class="action hints" @click="clickShowHints" v-if="showHints">
      <div class="hints-icon"><img :src="hintsIconSrc" /></div>
      <div>{{ hintsLabel }}</div>
      <div class="hotkey"><key-cup class="dark" :size="14">H</key-cup></div>
    </div>
    <hr class="separator" v-if="showHints" />
    <div class="action" @click="clickFullProfile" v-if="showFullProfile">
      <profile-icon></profile-icon>
      <div v-tooltip="fullProfileTooltip">Full Profile</div>
      <div class="hotkey"><key-cup class="dark" :size="14">P</key-cup></div>
    </div>
    <div class="action" v-if="editable" @click="clickQuickEdit">
      <pencil-icon></pencil-icon>
      <div>Quick Edit</div>
      <div class="hotkey"><key-cup class="dark" :size="14">E</key-cup></div>
    </div>
    <div class="action" v-if="editableFull" @click="fullProfileEditClick">
      <account-edit-icon></account-edit-icon>
      <div>Full Edit</div>
      <div class="hotkey"></div>
    </div>
    <div class="action has-submenu" v-if="showAddRelative">
      <div class="action" @click="toggleAddRelativeSubmenu">
        <plus-icon></plus-icon>
        <div>Add Relative</div>
        <div class="hotkey"><key-cup class="dark" :size="14">R</key-cup></div>
      </div>

      <div class="submenu" :style="relativesSubMenuStyle">
        <div v-if="relativesLoading" class="sub-action loading-message">Fetching relatives...</div>
        <template v-else>
          <div class="sub-action" :class="{disabled: !canAddParents}" @click="clickAddParent">
            <div>Parent</div>
            <div class="hotkey"><key-cup class="dark" :size="14">1</key-cup></div>
          </div>
          <div class="sub-action" @click="clickAddSpouse">
            <div>Spouse</div>
            <div class="hotkey"><key-cup class="dark" :size="14">2</key-cup></div>
          </div>
          <div class="sub-action" :class="{disabled: !canAddSibling}" @click="clickAddSibling">
            <div>Sibling</div>
            <div class="hotkey"><key-cup class="dark" :size="14">3</key-cup></div>
          </div>
          <div class="sub-action" @click="clickAddChild">
            <div>Child</div>
            <div class="hotkey"><key-cup class="dark" :size="14">4</key-cup></div>
          </div>
        </template>
      </div>
    </div>

    <div class="action more" v-if="!expanded" @click="expandActions">
      <more-icon :size="22"></more-icon>
      <div>More Actions</div>
    </div>
    <template v-if="expanded">
      <div class="action" key="view-tree" v-if="showViewTree" @click="clickViewTree">
        <eye-icon></eye-icon>
        <div>View Tree</div>
        <div class="hotkey"><key-cup class="dark" :size="14">T</key-cup></div>
      </div>
      <div class="action" key="view-lineage" v-if="showViewLineage" @click="clickViewLineage">
        <lineage-icon></lineage-icon>
        <div>View Lineage</div>
        <div class="hotkey"><key-cup class="dark" :size="14">L</key-cup></div>
      </div>
      <div class="action" key="delete" v-if="removable" @click="clickDelete">
        <delete-icon></delete-icon>
        <div>Delete</div>
        <div class="hotkey"><key-cup class="dark" :size="14">DEL</key-cup></div>
      </div>
    </template>
  </div>
</template>

<script>
import KeyCup from '@common/elements/icons/KeyCup';
import ProfileIcon from 'vue-material-design-icons/Account';
import AccountEditIcon from 'vue-material-design-icons/AccountEdit';
import MoreIcon from 'vue-material-design-icons/ChevronDown';
import DeleteIcon from 'vue-material-design-icons/Delete';
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare';
import PencilIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import EyeIcon from 'vue-material-design-icons/Sitemap';

import {CONTEXT_MENU_SECTIONS} from '@/components/modules/familyTree/constants';
import {isQuickSidebarHotkeyDisabled} from '@/components/modules/familyTree/tree/services.modals';

const SECTIONS = Object.values(CONTEXT_MENU_SECTIONS);

export default {
  props: {
    sections: {
      type: Array,
      validator: value => value.every(item => SECTIONS.includes(item)),
      default: () => SECTIONS,
    },
    hintsCount: {type: Number, required: false, default: 0},
    canAddParents: Boolean,
    canAddSibling: Boolean,
    relativesLoading: Boolean,
    personName: String,
  },
  created() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  data() {
    return {
      expanded: false,
      showAddRelativeSubmenu: false,
      keyP: 80,
      keyE: 69,
      keyT: 84,
      keyL: 76,
      keyR: 82,
      keyH: 72,
      key1: 49,
      key2: 50,
      key3: 51,
      key4: 52,
      keyDel: 46,
      keyBackspace: 8,
    };
  },
  computed: {
    actionsStyle() {
      let maxHeight = this.hintsCount ? 400 : 300;
      if (this.showAddRelativeSubmenu) {
        maxHeight += 200;
      }
      if (this.expanded) {
        maxHeight += 500;
      }
      return {'max-height': `${maxHeight}px`};
    },
    relativesSubMenuStyle() {
      let maxHeight = this.showAddRelativeSubmenu ? 200 : 0;
      return {'max-height': `${maxHeight}px`};
    },
    showFullProfile() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE);
    },
    removable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.DELETE);
    },
    editable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.EDIT);
    },
    editableFull() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE_EDIT);
    },
    showAddRelative() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
    },
    showViewTree() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_TREE);
    },
    showViewLineage() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_LINEAGE);
    },
    disableHotkeys() {
      return Boolean(this.$store.getters.galleryDisplayItemState);
    },
    hintsLabel() {
      return `Review ${this.hintsCount} Hint${this.hintsCount > 1 ? 's' : ''}`;
    },
    hintsIconSrc() {
      return this.$getAsset('/assets/mcr_logo_dude_transparent.png');
    },
    showHints() {
      return this.hintsCount;
    },
    fullProfileTooltip() {
      return {
        popperClass: 'text-center',
        placement: 'right',
        triggers: [],
        container: false,
        shown: this.$store.getters.showCardFullProfileTooltipTooltip,
        distance: 20,
        skidding: -2,
        content: 'Click for more details',
      };
    },
  },
  methods: {
    expandActions() {
      this.expanded = true;
    },
    toggleAddRelativeSubmenu() {
      this.showAddRelativeSubmenu = !this.showAddRelativeSubmenu;
    },
    onKeyDown(e) {
      console.log(e.which);
      if (this.disableHotkeys || isQuickSidebarHotkeyDisabled()) {
        return;
      }
      if (e.which === this.keyP && this.showFullProfile) {
        this.clickFullProfile();
      }
      if (e.which === this.keyE && this.editable) {
        this.clickQuickEdit();
      }
      if (e.which === this.keyR && this.showAddRelative) {
        this.toggleAddRelativeSubmenu();
      }
      if (e.which === this.keyH && this.showHints) {
        this.clickShowHints();
      }
      if (e.which === this.key1 && this.showAddRelative) {
        this.clickAddParent();
      }
      if (e.which === this.key2 && this.showAddRelative) {
        this.clickAddSpouse();
      }
      if (e.which === this.key3 && this.showAddRelative) {
        this.clickAddSibling();
      }
      if (e.which === this.key4 && this.showAddRelative) {
        this.clickAddChild();
      }
      if (e.which === this.keyT && this.showViewTree) {
        this.clickViewTree();
      }
      if (e.which === this.keyL && this.showViewLineage) {
        this.clickViewLineage();
      }
      if ((e.which === this.keyDel || e.which === this.keyBackspace) && this.removable) {
        this.clickDelete();
      }
    },
    clickFullProfile() {
      this.$emit('click-full-profile');
    },
    clickQuickEdit() {
      this.$emit('click-quick-edit');
    },
    fullProfileEditClick() {
      this.$emit('click-full-profile-edit');
    },
    clickViewTree() {
      this.$emit('click-view-tree');
    },
    clickViewLineage() {
      this.$emit('click-view-lineage');
    },
    clickDelete() {
      this.$emit('click-delete');
    },
    clickAddParent() {
      if (!this.canAddParents) {
        return this.$toasted.error(`Could not add parent because ${this.personName} already has two parents`);
      }
      this.$emit('click-add-parent');
    },
    clickAddSpouse() {
      this.$emit('click-add-spouse');
    },
    clickAddSibling() {
      if (!this.canAddSibling) {
        return this.$toasted.error(`Could not add sibling  because ${this.personName} has no parents yet`);
      }
      this.$emit('click-add-sibling');
    },
    clickAddChild() {
      this.$emit('click-add-child');
    },
    clickShowHints() {
      this.$emit('click-show-hints');
    },
  },
  components: {
    KeyCup,
    AccountEditIcon,
    ProfileIcon,
    PencilIcon,
    PlusIcon,
    MoreIcon,
    EyeIcon,
    LineageIcon,
    DeleteIcon,
  },
  name: 'PersonQuickViewActions',
};
</script>

<style lang="scss" scoped>
.person-quick-view-actions {
  transition: max-height 0.5s ease;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top: 1px solid $border-color-light;
  border-bottom: 1px solid $border-color-light;
  overflow: hidden;

  .action {
    display: flex;
    align-items: center;
    padding: 10px 10px;

    .material-design-icon {
      color: $icon-grey;
      margin-right: 20px;
      margin-top: -2px;
    }
    .hotkey {
      margin-left: auto;
      color: $supplemental-text-color;

      .keycup {
        margin: 0;
      }
    }

    &.has-submenu {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      margin: 0;
    }

    &:not(.has-submenu):hover {
      cursor: pointer;
      background: $background-color;
    }
  }
  .action.hints {
    font-weight: bold;
    color: $mcr-red;

    .hints-icon {
      background: $power-red;
      border-radius: 50%;
      margin-right: 20px;
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        margin-left: 1px;
        height: 23px;
      }
    }
  }
  .separator {
    margin: 6px 0;
  }

  .submenu {
    transition: max-height 0.3s ease;
    overflow: hidden;
    background: $background-alternate-color;

    .sub-action {
      padding: 10px 10px 10px 50px;
      display: flex;
      align-items: center;

      &.disabled {
        color: $supplemental-text-color;
      }

      &:hover {
        cursor: pointer;
        &:not(.disabled) {
          background: $background-color;
        }
      }
    }
  }

  .loading-message {
    font-style: italic;
  }
}
</style>
