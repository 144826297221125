<template>
  <div class="edit-cancel-modal-content">
    <div class="icon-container">
      <alert-icon :size="25"></alert-icon>
    </div>
    <div class="heading-6">You have unsaved changes</div>
    <div class="disclaimer text-sm">
      You have made changes to {{ name }}’s details but haven’t saved them yet. Here’s a list of changes to help you
      decide if you want to keep them.
    </div>
    <div class="card">
      <div class="compare-item" v-if="newItems.length">
        <div class="chip green small">New</div>
        <div v-for="item in newItems">
          <span>{{ item.label }}:</span
          ><span class="values"
            ><span class="value" v-for="value in item.values">{{ value }}</span></span
          >
        </div>
      </div>

      <div class="compare-item" v-if="editedItems.length || editedFacts.length">
        <div class="chip small">Edited</div>
        <div v-for="item in editedItems">
          <span>{{ item.label }}:</span><span class="values">{{ item.from }} > {{ item.to }}</span>
        </div>
        <div v-for="item in editedFacts">
          <span>{{ item.label }}:</span
          ><span>
            <span class="values">
              <span class="value" v-for="value in item.from">{{ value }}</span>
            </span>
            <span class="values"> > </span>
            <span class="values">
              <span class="value" v-for="value in item.to">{{ value }}</span></span
            >
          </span>
        </div>
      </div>

      <div class="compare-item" v-if="deletedItems.length">
        <div class="chip small">Deleted</div>
        <div v-for="item in deletedItems">
          <span>{{ item.label }}:</span
          ><span class="values"
            ><span class="value" v-for="value in item.values">{{ value }}</span></span
          >
        </div>
      </div>
    </div>
    <div class="buttons right-align">
      <mcr-button class="white text-sm bold" :disabled="saveLoading" @click="onDiscardClick">Discard</mcr-button>
      <mcr-button class="save text-sm bold" :loading="saveLoading" @click="saveChangesClick">Save Changes</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {formatApproximateDate, getFormattedResidenceText} from '@common/utils/utils';
import AlertIcon from 'vue-material-design-icons/AlertOutline';

export default {
  props: {
    unsavedFieldKeys: Array,
    unsavedFactIds: Array,
    personFormData: Object,
    personFormDataFacts: Array,
    personInitialData: Object,
    name: String,
    options: Object,
    modalName: String,
    saveLoading: Boolean,
  },
  data() {
    return {
      formInitialData: {...this.personInitialData},
    };
  },
  computed: {
    newItems() {
      return this.personFormDataFacts
        .filter(item => !!item._id && !item['DELETE'])
        .map(item => {
          return {label: item.fact_type.label, values: this.getFactDisplay(item)};
        });
    },
    keyToLabel() {
      return {
        birth_date: 'Birth Date',
        birth_location: 'Birth Place',
        death_date: 'Death Date',
        death_location: 'Death Place',
        first_names: 'First Names',
        gender: 'Gender',
        is_deceased: 'Status',
        surnames: 'Surnames',
        notes: 'Biography',
        generation_number: 'Generation Number',
        occupation: 'Occupation',
        residence_location: 'Residences',
      };
    },
    displayers() {
      return {
        birth_date: value => formatApproximateDate(value),
        birth_location: value => value.display_text,
        death_date: value => formatApproximateDate(value),
        death_location: value => value.display_text,
        gender: value => this.options.genders[value],
        notes: value => value,
        generation_number: value => value,
        occupation: value => value,
        is_deceased: value => (value ? 'Deceased' : value === false ? 'Living' : 'Unknown'),
        residence_location: values => values.map(value => getFormattedResidenceText(value)).join(', '),
      };
    },
    editedItems() {
      let personFields = this.unsavedFieldKeys
        .map(key => {
          let from = this.formInitialData[key];
          from = from && this.displayers[key] ? this.displayers[key](from) : 'Unknown';
          let to = this.personFormData[key];
          to = to && this.displayers[key] ? this.displayers[key](to) : 'Unknown';
          return from != to ? {label: this.keyToLabel[key], from: from, to: to} : null;
        })
        .filter(item => !!item);

      const initialFirstNames =
        this.formInitialData.first_names
          .map(value => `${value.value} (${this.options.first_name_types[value.type] || 'First Name'})`)
          .join(', ') || 'Unknown';
      const initialSurnames =
        this.formInitialData.surnames
          .map(value => `${value.value} (${this.options.surname_types[value.type] || 'Surname'})`)
          .join(', ') || 'Unknown';
      const newFirstNames =
        [
          ...this.personFormData.firstNamesEn.filter(name => name.value),
          ...this.personFormData.firstNamesCn.filter(name => name.value),
        ]
          .map(value => `${value.value} (${this.options.first_name_types[value.type] || 'First Name'})`)
          .join(', ') || 'Unknown';
      const newSurnames =
        [
          ...this.personFormData.surnamesEn.filter(name => name.value),
          ...this.personFormData.surnamesCn.filter(name => name.value),
        ]
          .map(value => `${value.value} (${this.options.surname_types[value.type] || 'Surname'})`)
          .join(', ') || 'Unknown';
      if (initialSurnames !== newSurnames) {
        personFields.unshift({label: 'Surnames', from: initialSurnames, to: newSurnames});
      }
      if (initialFirstNames !== newFirstNames) {
        personFields.unshift({label: 'First Names', from: initialFirstNames, to: newFirstNames});
      }
      return personFields;
    },
    editedFacts() {
      const fields = [];
      this.personFormDataFacts.forEach(fact => {
        if (fact.id && this.unsavedFactIds.includes(fact.id) && !fact['DELETE']) {
          const from = this.getFactDisplay(this.formInitialData.facts.find(f => fact.id === f.id));
          const to = this.getFactDisplay(fact);
          fields.push({label: fact.fact_type.label, from, to});
        }
      });
      return fields;
    },
    deletedItems() {
      return this.personFormDataFacts
        .filter(item => !!item.id && item['DELETE'])
        .map(item => {
          return {label: item.fact_type.label, values: this.getFactDisplay(item)};
        });
    },
  },
  methods: {
    getFactDisplay(fact) {
      const {value, start_date, end_date, place_display_text, cemetery, info} = fact;
      return [
        value,
        start_date ? formatApproximateDate(start_date) : '',
        end_date ? formatApproximateDate(end_date) : '',
        place_display_text,
        cemetery ? cemetery.name : '',
        ...info.map(infoItem => infoItem.value),
      ].filter(text => !!text);
    },
    onDiscardClick() {
      this.$modal.hide(this.modalName);
      this.$emit('discard');
    },
    saveChangesClick() {
      this.$emit('save');
    },
  },
  components: {McrButton, AlertIcon},
  name: 'EditCancelModalContent',
};
</script>

<style lang="scss" scoped>
.edit-cancel-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  .icon-container {
    color: $primary-400;
    background: $primary-50;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .heading-6 {
    margin: 8px 0;
  }
  .disclaimer {
    text-align: center;
    color: $neutral-600;
    max-width: 360px;
  }

  .card {
    max-width: 375px;
    margin-top: 20px;
    padding: 20px 16px;
    background: white;
    box-shadow: $slight-shadow;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .compare-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 8px;

      .values {
        color: $neutral-500;
        margin-left: 3px;
        > .value:not(:last-child)::after {
          content: ' • ';
          color: $neutral-400;
        }
      }
    }
  }

  .buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    align-self: stretch;
  }
}
</style>
