<template>
  <div class="marriage-details-block">
    <div class="content-item">
      <div class="label">Marriage Details</div>
      <div class="values">
        <approximate-date-field
          :day="marriageDate.day"
          :month="marriageDate.month"
          :year="marriageDate.year"
          :approximation-years="marriageDate.approximation_years"
          @change-day="changeMarriageDate({day: $event})"
          @change-month="changeMarriageDate({month: $event})"
          @change-year="changeMarriageDate({year: $event})"
          @change-approximation-years="changeMarriageDate({approximation_years: $event})"
        ></approximate-date-field>
        <div class="place-container">
          <div class="input-label">Marriage Place</div>

          <family-tree-location-multiselect-container
            placeholder=""
            multiselect-classes="bordered"
            :always-show-remove="true"
            :location="marriagePlace || {}"
            :family-tree-id="familyTreeId"
            @clear="clearMarriageLocation"
            @select="changeMarriageLocation"
          ></family-tree-location-multiselect-container>
        </div>

        <div class="is-divorced-container" :class="{checked: isDivorced}">
          <input type="checkbox" v-model="isDivorced" id="is-divorced" />
          <label for="is-divorced" class="text-sm">Divorced</label>
        </div>
      </div>
    </div>
    <div class="content-item divorced-info-container" v-if="isDivorced">
      <div class="label">Divorce Details</div>
      <div class="values">
        <approximate-date-field
          :day="divorceDate.day"
          :month="divorceDate.month"
          :year="divorceDate.year"
          :approximation-years="divorceDate.approximation_years"
          @change-day="changeDivorceDate({day: $event})"
          @change-month="changeDivorceDate({month: $event})"
          @change-year="changeDivorceDate({year: $event})"
          @change-approximation-years="changeDivorceDate({approximation_years: $event})"
        ></approximate-date-field>
        <div class="place-container">
          <div class="input-label">Divorce Place</div>

          <family-tree-location-multiselect-container
            placeholder=""
            multiselect-classes="bordered"
            :always-show-remove="true"
            :location="divorcePlace || {}"
            :family-tree-id="familyTreeId"
            @clear="clearDivorceLocation"
            @select="changeDivorceLocation"
          ></family-tree-location-multiselect-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FamilyTreeLocationMultiselectContainer from '@/components/common/FamilyTreeLocationMultiselectContainer';
import approximateDateField from '@/components/common/tree/approximateDateField';

export default {
  props: {
    initialData: Object,
    familyTreeId: [String, Number],
  },
  data() {
    return {
      marriageDate: (this.initialData && this.initialData.marriage_date) || {},
      marriagePlace: (this.initialData && this.initialData.marriage_place) || null,
      divorceDate: (this.initialData && this.initialData.divorce_date) || {},
      divorcePlace: (this.initialData && this.initialData.divorce_place) || null,
      isDivorced: (this.initialData && this.initialData.is_divorced) || false,
    };
  },
  methods: {
    changeMarriageDate(data) {
      this.marriageDate = {...this.marriageDate, ...data};
    },
    changeDivorceDate(data) {
      this.divorceDate = {...this.divorceDate, ...data};
    },
    changeMarriageLocation(location) {
      this.marriagePlace = location;
    },
    clearMarriageLocation() {
      this.changeMarriageLocation(null);
    },
    changeDivorceLocation(location) {
      this.divorcePlace = location;
    },
    clearDivorceLocation() {
      this.changeDivorceLocation(null);
    },
    getDataForSave() {
      return {
        marriage_place: this.marriagePlace,
        marriage_date: this.approximateDateValuesToInt(this.marriageDate),
        is_divorced: this.isDivorced,
        divorce_place: this.divorcePlace,
        divorce_date: this.approximateDateValuesToInt(this.divorceDate),
      };
    },
    approximateDateValuesToInt(date) {
      let newData = date ? {...date} : date;
      if (newData) {
        ['day', 'month', 'year', 'approximation_years'].forEach(key => {
          newData[key] = parseInt(newData[key]) || null;
        });
      }
      return newData;
    },
  },
  components: {approximateDateField, FamilyTreeLocationMultiselectContainer},
  name: 'MarriageDetailsBlock',
};
</script>

<style lang="scss" scoped>
.marriage-details-block {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .content-item {
    display: flex;
    position: relative;

    .label {
      font-weight: 800;
      color: $neutral-500;
      width: 200px;
      flex-shrink: 0;
    }

    .values {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      flex-grow: 1;
      min-width: 0;
    }
  }

  .is-divorced-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 8px 0 0;

    input,
    label {
      cursor: pointer;
    }

    input#is-divorced {
      margin-bottom: 0;
      margin-top: 0;
    }

    &.checked {
      padding-bottom: 20px;
      border-bottom: 1px solid #e5e7eb;
    }
  }
  .place-container {
    margin-top: 16px;
  }
  .divorced-info-container {
    margin-top: 12px;
    .place-container {
      margin-bottom: 16px;
    }
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    .content-item {
      flex-direction: column;
      row-gap: 12px;
    }
    .place-container {
      margin-top: 12px;
    }
    .is-divorced-container {
      margin-top: 4px;
      &.checked {
        padding-bottom: 16px;
      }
    }
    .divorced-info-container {
      margin-top: 4px;
      .place-container {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
