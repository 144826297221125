<template>
  <div class="tab-content tab-relationships">
    <div class="item-block person-relatives-block" ref="block">
      <div class="header">
        <div class="heading-6">{{ title }}</div>
        <v-dropdown v-if="addAllowed" :shown.sync="isPopoverOpen" :autoHide="true">
          <mcr-button class="white small"><plus-icon :size="20" /><span>Add Relatives</span></mcr-button>
          <div slot="popper" class="popover-content">
            <div v-if="!personHasBothParents" class="option" @click="addParentClick">Parent</div>
            <div class="option" @click="addSpouseClick">Spouse</div>
            <div v-if="personHasParents" class="option" @click="addSiblingClick">Sibling</div>
            <div class="option" @click="addChildClick">Child</div>
          </div>
        </v-dropdown>
      </div>
      <div class="body">
        <div v-for="item in relativesItems" :key="item.label" class="content-item">
          <div class="label text-md text-lg-mobile">{{ item.label }}</div>
          <div class="persons">
            <div v-for="person in item.persons" class="relative-item-wrapper">
              <relative-item
                :person="person"
                :profile-picture="relatives.profile_pictures[person.object_id] || ''"
                :spouse-meta="item.meta[person.object_id] || {}"
                :meta-editable="item.metaEditable"
                :key="person.object_id"
                @edit-spouse-meta="openSpouseMetaEditModal"
              ></relative-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import PlusIcon from 'vue-material-design-icons/PlusCircleOutline';
import {mapGetters} from 'vuex';

import {
  CHILD_RELATION_TYPE,
  PARENT_RELATION_TYPE,
  SIBLING_RELATION_TYPE,
  SPOUSE_RELATION_TYPE,
  UNKNOWN_NAME,
} from '@/components/modules/familyTree/constants';
import editSpouseMetaModalContent from '@/components/modules/familyTree/tree/modals/editSpouseMetaModalContent';
import {openAddPersonModal} from '@/components/modules/familyTree/tree/services.modals';

import RelativeItem from './components/RelativeItem';

export default {
  data() {
    return {
      isPopoverOpen: false,
    };
  },
  computed: {
    ...mapGetters(['familyTreePersonState', 'familyRelativesByPersonIdState']),
    name() {
      const names = this.familyTreePersonState.first_names;
      return names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
    },
    title() {
      return `${this.name}'s Relatives`;
    },
    personId() {
      return parseInt(this.$route.params.personId);
    },
    addAllowed() {
      return this.familyTreePersonState.is_write_allowed;
    },
    relatives() {
      return this.familyRelativesByPersonIdState[this.personId] || {};
    },
    separateSiblings() {
      const separatedSiblings = {
        fullSiblings: [],
        halfSiblings: [],
      };
      if (!this.relatives.siblings) {
        return separatedSiblings;
      }
      for (let sibling of this.relatives.siblings) {
        let isFullSibling = true;
        this.relatives.parents.map(p => {
          if (!sibling.parents_ids.includes(p.object_id)) {
            isFullSibling = false;
          }
        });
        if (isFullSibling) {
          separatedSiblings.fullSiblings.push(sibling);
        } else {
          separatedSiblings.halfSiblings.push(sibling);
        }
      }
      return separatedSiblings;
    },
    personHasParents() {
      return Boolean(this.relatives && this.relatives.parents && this.relatives.parents.length);
    },
    personHasBothParents() {
      return this.relatives && this.relatives.parents && this.relatives.parents.length === 2;
    },
    relativesItems() {
      return [
        {label: 'Parents', persons: this.relatives.parents, meta: {}},
        {label: 'Full Siblings', persons: this.separateSiblings.fullSiblings, meta: {}},
        {label: 'Half Siblings', persons: this.separateSiblings.halfSiblings, meta: {}},
        {label: 'Spouses', persons: this.relatives.spouses, meta: this.relatives.spouses_meta, metaEditable: true}, // todo: check user has write rights
        {label: 'Children', persons: this.relatives.children, meta: {}},
      ].filter(item => item.persons && item.persons.length);
    },
  },
  methods: {
    addParentClick() {
      return this.openAddPersonModal(null, PARENT_RELATION_TYPE);
    },
    addSpouseClick() {
      return this.openAddPersonModal(null, SPOUSE_RELATION_TYPE);
    },
    addSiblingClick() {
      return this.openAddPersonModal(null, SIBLING_RELATION_TYPE);
    },
    addChildClick() {
      return this.openAddPersonModal(null, CHILD_RELATION_TYPE);
    },
    openAddPersonModal(initialData, relationType) {
      this.isPopoverOpen = false;
      const modalName = 'add-relative';
      const callback = () => {
        this.$store.dispatch('fetchFamilyTreePersonRelativesAction', this.familyTreePersonState.object_id).then(() => {
          this.$toasted.success('Relative created successfully!');
          this.$modal.hide(modalName);
        });
      };
      openAddPersonModal(
        initialData,
        relationType,
        this.familyTreePersonState,
        this.familyTreePersonState.family_tree_id,
        modalName,
        false,
        false,
        callback,
        this
      );
    },
    openSpouseMetaEditModal({person, meta}) {
      const props = {
        name: person.full_name || UNKNOWN_NAME,
        initialData: meta,
        familyTreeId: this.familyTreePersonState.family_tree_id,
        coupleId: meta.couple_id,
        couplePersonIds: [person.object_id, this.familyTreePersonState.object_id],
      };
      let modalParams = {
        classes: 'clear_modal white_modal',
        scrollable: true,
        height: 'auto',
        name: 'edit-spouse-meta',
      };
      this.$modal.show(editSpouseMetaModalContent, props, modalParams, {});
    },
  },
  components: {McrButton, PlusIcon, RelativeItem},
  name: 'TabRelationships',
};
</script>

<style lang="scss" scoped>
.tab-relationships {
  .body {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    .content-item {
      .label {
        font-weight: 800;
        color: $neutral-500;
        margin-bottom: 8px;
      }

      .persons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .body {
      padding: 16px 0;
      row-gap: 20px;
      .content-item {
        .label {
          margin: 0 16px 4px;
        }

        .persons {
          display: block;

          .relative-item-wrapper:not(:last-child) {
            .relative-item {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
</style>
