<template>
  <v-dropdown
    :shown.sync="isPopoverOpen"
    class="context-menu-popover"
    :popper-class="popoverClass"
    :autoHide="true"
    @hide="onClose"
  >
    <dots-vertical-icon class="my-icon" :size="30" title="Context menu"></dots-vertical-icon>
    <div slot="popper" class="popover-content">
      <div class="overlay" @click="hidePopover"></div>
      <slot name="header"></slot>
      <popover-action-button v-if="editable" label="Edit" :help-text="editHelpText" @click.native="editClick">
        <pencil-icon class="my-icon" :size="iconSize" title="Edit"></pencil-icon>
      </popover-action-button>
      <popover-action-button v-if="showAddRelative" label="Add Relative" @click.native="addRelativeClick">
        <plus-icon class="my-icon" :size="iconSize" title="Add Relative"></plus-icon>
      </popover-action-button>
      <person-details-add-relative-popover
        class="opaque"
        v-if="showAddRelativeSubmenu"
        :can-add-sibling="canAddSibling"
        :can-add-parents="canAddParent"
        @click-add-parent="addParentClick"
        @click-add-spouse="addSpouseClick"
        @click-add-sibling="addSiblingClick"
        @click-add-child="addChildClick"
      ></person-details-add-relative-popover>
      <popover-action-button v-if="showViewTree" label="View Tree" @click.native="viewTreeClick">
        <eye-icon class="my-icon" :size="iconSize" title="View Tree"></eye-icon>
      </popover-action-button>
      <popover-action-button v-if="showViewLineage" label="View Lineage" @click.native="viewLineageClick">
        <lineage-icon class="my-icon" :size="iconSize" title="View Lineage"></lineage-icon>
      </popover-action-button>
      <popover-action-button v-if="removable" label="Delete" @click.native="deleteClick">
        <delete-icon class="my-icon" :size="iconSize" title="Delete"></delete-icon>
      </popover-action-button>
      <popover-action-button v-if="isMobile" class="big" label="Cancel" @click.native="hidePopover">
        <close-icon class="my-icon" :size="iconSize" title="Cancel"></close-icon>
      </popover-action-button>
    </div>
  </v-dropdown>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';
import DeleteIcon from 'vue-material-design-icons/Delete';
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical';
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare';
import PencilIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import EyeIcon from 'vue-material-design-icons/Sitemap';

import PopoverActionButton from '@/components/common/modals/popoverActionButton';

import {CONTEXT_MENU_SECTIONS} from '@/components/modules/familyTree/constants';
import PersonDetailsAddRelativePopover from '@/components/modules/familyTree/tree/modals/personDetailsAddRelativePopover';

const SECTIONS = Object.values(CONTEXT_MENU_SECTIONS);

export default {
  props: {
    sections: {
      type: Array,
      validator: value => value.every(item => SECTIONS.includes(item)),
      default: () => SECTIONS,
    },
    canAddParent: Boolean,
    canAddSibling: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {
      iconSize: 28,
      showAddRelativeSubmenu: false,
      isPopoverOpen: false,
    };
  },
  computed: {
    removable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.DELETE);
    },
    editable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.EDIT);
    },
    showAddRelative() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
    },
    showViewTree() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_TREE);
    },
    showViewLineage() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_LINEAGE);
    },
    popoverClass() {
      return this.isMobile ? 'mobile-context' : '';
    },
    editHelpText() {
      return this.isMobile ? '(Double-Tap Card)' : '';
    },
  },
  methods: {
    editClick() {
      this.$emit('click-edit');
    },
    viewTreeClick() {
      this.$emit('click-view-tree');
    },
    viewLineageClick() {
      this.$emit('click-view-lineage');
    },
    deleteClick() {
      this.hidePopover();
      this.$emit('click-delete');
    },
    addRelativeClick() {
      this.showAddRelativeSubmenu = !this.showAddRelativeSubmenu;
    },
    addParentClick() {
      this.hidePopover();
      this.$emit('click-add-parent');
    },
    addSpouseClick() {
      this.hidePopover();
      this.$emit('click-add-spouse');
    },
    addSiblingClick() {
      this.hidePopover();
      this.$emit('click-add-sibling');
    },
    addChildClick() {
      this.hidePopover();
      this.$emit('click-add-child');
    },
    hidePopover() {
      this.isPopoverOpen = false;
    },
    onClose() {
      this.showAddRelativeSubmenu = false;
    },
  },
  components: {
    PopoverActionButton,
    DotsVerticalIcon,
    PencilIcon,
    EyeIcon,
    LineageIcon,
    DeleteIcon,
    PlusIcon,
    CloseIcon,
    PersonDetailsAddRelativePopover,
  },
  name: 'personDetailsContextMenuPopover',
};
</script>

<style lang="scss" scoped>
.context-menu-popover {
  position: absolute;
  top: 20px;
  right: 55px;
  color: #777;
  cursor: pointer;

  @media only screen and (max-width: $breakpoint-phablet) {
    position: fixed;
  }
}
</style>
